<footer class="footer-area" >
    <div class="container" >
        <div class="row">
          <div class="main-banner-white-home" style="padding-top:3em; background-color:#F0F0F0">
            <div class="">
              <div   >
                <h6 class="mb-3 col-6 texto-negro" style="font-size: 18px;"[innerHTML]="'HERE TO SUPPORT'|translate"></h6>
                <p class="col-6 texto-negro" style="font-size:14px"[innerHTML]="'With the best service for the BYD BatteryBox'|translate"></p>
              </div>
                <br>
                <div class=" pb-100">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                      <div class="services-content"style="background-color: #ffffff;height: 80px">
                          <div >
                            <small class="texto-negro letra-bold" style="font-size: small;"[innerHTML]="'WANT TO KNOW ABOUT OUR PRODUCTS?'|translate"></small>
                          </div>
                      </div>
                      <div class="services-content-home"style="background-color: #439B27;">
                          <div >
                            <a [routerLink]="['/products']" [queryParams]="{ section: 'products' }"><b class="texto-blanco services-content-home"style="font-size: smaller;"[innerHTML]="'CLICK HERE'|translate"></b></a>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                      <div class="services-content"style="background-color: #ffffff;height: 80px">
                        <small class="texto-negro letra-bold"[innerHTML]="'NEED ASSITANCE?'|translate"></small>
                          <div >
                          </div>
                      </div>
                      <div class="services-content-home"style="background-color: #DF280D;">

                          <div >
                            <a [routerLink]="['/service']" [queryParams]="{ section: 'service', id: 'servicio'  }"><b class="texto-blanco services-content-home"style="font-size: smaller;"[innerHTML]="'CLICK HERE'|translate"></b></a>
                          </div>
                          
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                      <div class="services-content"style="background-color: #ffffff;height: 80px">
                        <small class="texto-negro letra-bold"[innerHTML]="'LOOKING FOR PRODUCT TRAINING?'|translate"></small>
                          <div >
                          </div>
                      </div>
                      <div class="services-content-home"style="background-color: black;">

                          <div >
                            <a [routerLink]="['/service']" [queryParams]="{ section: 'service', id: 'servicio1' }"><b class="texto-blanco services-content-home"style="font-size: smaller;" [innerHTML]="'CLICK HERE'|translate"></b></a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div >
            </div>
        </div>
        </div>
          <div class="row ">
            <div class="row col-md-5  me-3">
              <div class="col-5">
              <p class="mt-3 fuente-pop letra-bold" style="color:black;font-size:small">EFT.COM</p>
              <div><a routerLink="/Home" style="font-size:small;" class="texto-negro"[innerHTML]="'Home'|translate"></a></div>
              <div><a routerLink="/about" style="font-size:small;" class=" texto-negro "[innerHTML]="'About'|translate"></a></div>
              <div><a routerLink="/products" style="font-size:small" class="texto-negro"[innerHTML]="'Products'|translate"></a></div>
              <div><a routerLink="/service" style="font-size:small" class="texto-negro"[innerHTML]="'Service'|translate"></a></div>
              <div><a routerLink="/news" style="font-size:small" class="texto-negro"[innerHTML]="'News'|translate"></a></div>
            </div>
              <div class="col-5">
              <p class="fuente-pop letra-bold" style="color:black; font-size:small; margin-top: 1rem">EFT PROFESIONALS</p>
              <div><a href="https://docs.eft-systems.de/documentos" style="font-size:small" class="texto-negro"[innerHTML]="'Downloads'|translate"></a></div>
              <div><a href="https://support.eft-systems.de/" style="font-size:small" class="texto-negro"[innerHTML]="'Installer Sing-In'|translate"></a></div>

            </div>
            </div>
            <div class="col-md-2  me-3" >
              
            </div>
            <div class="row col-md-5 ">
              <!-- <div class="col-md-6">
              <div class="letra-bold" style="color:black; font-size:small; margin-top: 1rem">SOCIAL</div>
              <div style="font-size:small" class="texto-negro mt-3">Linkendin</div>
              <div style="font-size:small" class="texto-negro">Facebook</div>
              <div style="font-size:small"class="texto-negro">Twitter  </div>
              </div> -->
            <div class="col-md-6">
              <div class="letra-bold" style="color:black; font-size:small; margin-top: 1rem"[innerHTML]="'CONTACT US'|translate"></div>
              <div style="font-size:small" class="texto-negro mt-3"[innerHTML]="'info@eft-systems.de'|translate"></div>
              <div style="font-size:small" class="texto-negro"[innerHTML]="'EFT-Systems GmbH'|translate"></div>
              <div style="font-size:small" class="texto-negro"[innerHTML]="'Bruchtannenstr. 28'|translate"></div>
              <div style="font-size:small" class="texto-negro"[innerHTML]="'63801 Kleinostheim'|translate"></div>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
          <div class="row">
          <div class="col-3">
            <p class="letra-bold" style="font-size:x-small">©2023 EFT-SYSTEMS. ALL RIGHTS RESERVED.</p>
          </div>
          <div class="col-9" style="text-align: right;">
            <a class="letra-bold" style="font-size:x-small" routerLink="/politicas">Impressum-AGB</a>
          </div>
        </div>
          </div>
      </div>
</footer>

