<a class="scroll-to-top hidden" (click)="scrollToTop()">
    <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-white-products "style="height:1065px">
    <div class=" pt-70 texto-centrado-h1" style="padding-left: 23%;padding-right: 23%;">
        <h1 class=" texto-negro titulo-chico" style="margin-top:5em"[innerHTML]="'OUR PRODUCTS IN A NUTSHELL'|translate"></h1>
        <p class="text-center texto-negro mt-3 texto-chico"[innerHTML]="'texto35'|translate"></p>
        <br>
        <div class="container container-resp" style="padding-left: 15%;padding-right: 15%;">
            <div class="row ">
                <div class="col-3 d-flex flex-column align-items-center">
                    <img src="/assets/eft/iconografia/products/SVG/highpower.svg" alt="" height="auto" width="60px">
                    <h6 class="letra-bold mt-1 text-center resp-ico texto-chico"[innerHTML]="'texto36'|translate"></h6>
                </div>
                <div class="col-3 mt-3 d-flex flex-column align-items-center">
                    <img src="/assets/eft/iconografia/products/SVG/easyinstallation.svg" alt="" height="auto" width="60px">
                    <h6 class="letra-bold mt-1 text-center texto-chico resp-ico" [innerHTML]="'texto37'|translate"></h6>
                </div>
                <div class="col-3 mt-3  d-flex flex-column align-items-center">
                    <img src="/assets/eft/iconografia/products/SVG/flexibleconf.svg" alt="" height="auto" width="60px">
                    <h6 class="letra-bold mt-1 text-center texto-chico resp-ico" [innerHTML]="'texto38'|translate"></h6>
                </div>
                <div class="col-3 d-flex flex-column align-items-center ml-3">
                    <img src="/assets/eft/iconografia/products/SVG/extend.svg" alt="" height="auto" width="60px">
                    <h6 class="letra-bold mt-1 text-center texto-chico resp-ico"[innerHTML]="'texto39'|translate"></h6>
                </div>
            </div>
        </div>
    </div>
    <img class="img-products-rep" src="/assets/eft/fotos/PRODUCTS/Products1.png" alt="" style="width:100%;height: 600px;">
  </div>
  <div class="main-banner-products" style="background-color:#ffffff"style="height:auto" >
    <div class="container">
        <div class="row " >
            <div class="col-lg-6 col-md-12 " style=" padding-top: 12%;">
                <div >
                    <h1 class="text-start texto-negro titulo-chico" [innerHTML]="'MEET THE BATTERYBOX PREMIUM FAMILIY'|translate"></h1><br>
                    <p class="texto-negro texto-chico"[innerHTML]="'texto40'|translate">
                    </p><br>
                    <a (click)="onClick('seccion-hsv')"><img src="/assets/eft/iconografia/menu/SCROLL.svg" height="30px"></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 d-flex align-items-center container" style="padding-top: 12%;">
                <div class="row">
                  <div class="col-sm-6 col-lg-6 text-center mt-5">
                    <img src="/assets/eft/iconografia/products/SVG/LFP.svg" alt="" class="svg-med">
                    <h6 class="letra-bold texto-chico"[innerHTML]="'texto41'|translate" ></h6>
                  </div>
                  <div class="col-sm-6 col-lg-6 text-center mt-5">
                    <img src="/assets/eft/iconografia/products/SVG/HIGH.svg" alt="" class="svg-med">
                    <h6 class="letra-bold texto-chico" [innerHTML]="'texto42'|translate"></h6>
                  </div>
                  <div class="col-sm-6 col-lg-6 text-center mt-5">
                    <img src="/assets/eft/iconografia/products/SVG/COMPATIBLE.svg" alt="" class="svg-med">
                    <h6 class="letra-bold texto-chico" [innerHTML]="'texto43'|translate"></h6>
                  </div>
                </div>
              </div>
              
            </div>
    </div>

    </div>
        <div class="main-banner-products-img " style="padding-top: 5em; height:730px" >
            <img src="/assets/eft/fotos/PRODUCTS/Products2.jpg" alt="" >
          </div>
    
      <div class="main-banner-products-img" style="background-color:#ffffff" >
        <div class="row no-gutter" id="seccion-hsv">
            <div class="col-lg-6 col-md-12 oren1" style=" padding-top: 12%;">
                <div class="container ms-5 cont-resp">
                    <h1 class="text-start texto-negro titulo-chico"[innerHTML]="'PREMIUM HVS/HVM'|translate"></h1>
                    <small class="texto-chico"[innerHTML]="'texto44'|translate"></small><br><br><br>

                    <small class="letra-bold mb-3 "[innerHTML]="'texto45'|translate"></small>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 " [innerHTML]="'texto46'|translate"></h6>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 "[innerHTML]="'Two Distinct Modules to Cover the Complete Range of System Sizes'|translate"></h6>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 "[innerHTML]="'texto47'|translate" ></h6>
                    <div class="d-flex mt-5">
                        <a class="btn btn-no btn-byd texto-chico"  href="assets/pdf/230530_BYD_Battery-Box_Premium_HVS&HVM_Datasheet_V1.7_ESP-647eef2bd057e.pdf" target="_blank" rel="noopener noreferrer"[innerHTML]="'Download Technical Data Sheet'|translate">
                            
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 img-resp-prod" style="background-color: #F0F0F0; height: 800px; order: 2; text-align: center;">
                <img src="/assets/eft/fotos/PRODUCTS/Premium LVS.png" style="margin: 0 auto; padding-top: 8%; display: block;">
            </div>
            
        </div>
    </div>
    <div class="main-banner-products-img" style="background-color:#ffffff" >
        <div class="row no-gutter" >
            <div class="col-lg-6 col-md-12 text-end img-resp-prod orden2"  >
                <img src="/assets/eft/fotos/PRODUCTS/Premium-HVM.jpg" alt="">
            </div>
            <div class="col-lg-6 col-md-12 " style=" padding-top: 12%; order: 1">
                <div class="container ms-5 cont-resp">
                    <h1 class="text-start texto-negro titulo-chico" style="font-size:34px;"[innerHTML]="'PREMIUM LVS'|translate">PREMIUM LVS</h1>
                    <small class="texto-chico"[innerHTML]="'Scalable from 4 kWh to 256 kWh'|translate"></small><br><br><br>
                    <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'texto48'|translate"></h6>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'Patented Internal Plug Design Requires No Additional Wiring'|translate"></h6>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'texto49'|translate"></h6>
                    <div class="d-flex mt-5">
                        <a class="btn btn-no btn-byd2 texto-chico" style=" background-color:#5EBAAB; border-color:#5EBAAB" href="assets/pdf/230318_Premium_Datasheet_LVS_V2.2_ESP-64153691cfcca (1).pdf" target="_blank" rel="noopener noreferrer"[innerHTML]="'Download Technical Data Sheet'|translate">
                            
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner-products-img" style="background-color:#ffffff" >
        <div class="row no-gutter" >
            <div class="col-lg-6 col-md-12 " style=" padding-top: 12%;">
                <div class="container ms-5 cont-resp">
                    <h1 class="text-start texto-negro titulo-chico"[innerHTML]="'PREMIUM LVL'|translate"></h1>
                    <small [innerHTML]="'texto50'|translate"></small><br><br><br>

                    <h6 class="letra-bold mb-3 texto-chico"[innerHTML]="'Space Saving via the Ability to Stack 2 Premium Batteries'|translate"></h6>
                    <hr class="custom-hr">
                    <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'Maximum Flexibility for any Application with up to 64 Modules Connected in Parallel'|translate"></h6>
                    <div class="d-flex mt-5">
                        <a class="btn btn-no btn-byd3 texto-chico" style="font-size:small" href="assets/pdf/230318_Premium_Datasheet_LVL_V1.2_ESP-6415497891857.pdf" target="_blank" rel="noopener noreferrer"[innerHTML]="'Download Technical Data Sheet'|translate">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 img-resp-prod">
                <img src="/assets/eft/fotos/PRODUCTS/Premium-LVL.jpg" alt="">
            </div>
        </div>
    </div>
    <div class="main-banner-red d-flex align-items-center justify-content-center" style="background-color: black;" id="welcome-raion">
        <div class="container">
        <div class="d-flex justify-content-between align-items-center w-100">
            <div class="col-lg-6">
                <p class="text-start texto-blanco mt-2 titulo-chico" [innerHTML]="'Explore all products'|translate"></p>
            </div>
            <div class="col-lg-6">
                <a routerLink="/products"><h1 class="text-end texto-blanco mt-3 titulo-chico" style=" padding-right: 3em;">➔</h1></a>
            </div>
        </div>
    </div>
    </div>
    