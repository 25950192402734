import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-home-eleven',
    templateUrl: './home-eleven.component.html',
    styleUrls: ['./home-eleven.component.scss']
})
export class HomeElevenComponent implements OnInit {
    scrollToTop() {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    
      @HostListener('window:scroll', [])
      onWindowScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón
    
        const scrollToTopButton = document.querySelector('.scroll-to-top');
        if (scrollToTopButton) {
          if (scrollPosition > scrollThreshold) {
            scrollToTopButton.classList.remove('hidden');
          } else {
            scrollToTopButton.classList.add('hidden');
          }
        }
      }
    

    constructor(private viewportScroller: ViewportScroller) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    myStyle: object = {};
    myParams: object = {};
    width: number = 100;
    height: number = 100;
 
    ngOnInit() {
        this.myStyle = {
            'top': 0,
            'left': 0,
            'width': '100%',
            'height': '100%',
            'position': 'absolute'
        };
        this.myParams = {
            particles: {
                number: {
                    value: 80,
                },
                color: {
                    value: '#ffffff'
                },
                shape: {
                    type: 'triangle',
                },
            }
        };
    }

}