import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {UntypedFormBuilder, FormGroup, FormControl, Validators, UntypedFormGroup, UntypedFormArray, FormBuilder} from '@angular/forms';
import { ContactFormService } from 'src/app/contact-form.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-six',
  templateUrl: './home-six.component.html',
  styleUrls: ['./home-six.component.scss']
})
export class HomeSixComponent implements OnInit {

  public formularioEnviado = false;
  public formLoading = false;
  public isSubmitted = false;
  isSpanishLang: boolean;
  isEnglishLang: boolean;

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }


   contactForm  = this.formBuilder.group({
      first_name:['', Validators.required],
      surname:['', [Validators.required]], 
      company_name:['', Validators.required], 
      email: ['', [Validators.required, Validators.email]],
      message:['', Validators.required], 
  });
  ngForm: any;

  copyText(textToCopy: string) {
    const tempInput = document.createElement('input');
    tempInput.setAttribute('value', textToCopy);
    document.body.appendChild(tempInput);
    
    tempInput.select();
    document.execCommand('copy');
    
    document.body.removeChild(tempInput);
    
    alert('Texto copiado: ' + textToCopy);
  }
  

  constructor(private viewportScroller: ViewportScroller,private route: ActivatedRoute, private router: Router,public formBuilder: FormBuilder,private contactService: ContactFormService,private translate: TranslateService) {
    this.isSpanishLang = this.translate.currentLang === 'es';
    this.isEnglishLang = this.translate.currentLang === 'en';

  }

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.isSpanishLang = event.lang === 'es';
      this.isEnglishLang = event.lang === 'en';
    });
  }

    submit(){
    this.formLoading = true;
    this.isSubmitted = true;
    if (this.contactForm.invalid) {
        console.log(this.contactForm);
        this.formLoading = false;
        return;
      }
    

     this.contactService.sendForm(this.contactForm.value).subscribe({
         next: (r: any) => {
           console.log(r)
           this.formularioEnviado = true
           this.formLoading = false

         },
         error: (e) => console.log(e)
        
       })
}

}
