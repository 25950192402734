import { ViewportScroller } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-eight',
  templateUrl: './home-eight.component.html',
  styleUrls: ['./home-eight.component.scss']
})
export class HomeEightComponent {
  isDeutchLang: boolean;
  isEnLang: boolean;
  isItLang: boolean;
  isFrLang: boolean;
  isEsLang: boolean;

  collapsed: {[key: string]: boolean} = {
    collapseDatasheet: true,
    collapseDatasheet2: true,
    collapseDatasheet3: true,
    collapseCompatibility: true,
    collapseCompatibility2: true,
    collapseCompatibility3: true,
    collapseservice: true,
    collapseservice2: true,
    collapseservice3: true,
    collapseservice5: true,
    collapseservice4: true,
    collapseservice6: true,
    collapsemanuals: true,
    collapsemanuals2: true,
    collapsemanuals3: true,
    collapsewarranty: true,
    collapsewarranty2: true,
    collapsewarranty3: true,
    collapsecertificates: true,
    collapsecertificates2: true,
    collapsecertificates3: true,
  };
  constructor(private viewportScroller: ViewportScroller, private translate: TranslateService) {
    this.isDeutchLang = this.translate.currentLang === 'de';
    this.isEnLang = this.translate.currentLang === 'en';
    this.isItLang = this.translate.currentLang === 'it';
    this.isFrLang = this.translate.currentLang === 'fr';
    this.isEsLang = this.translate.currentLang === 'es';

  }
  ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.isDeutchLang = event.lang === 'de';
      this.isEnLang = event.lang === 'en';
      this.isItLang = event.lang === 'it';
      this.isFrLang = event.lang === 'fr';
      this.isEsLang = event.lang === 'es';
    });


  }

  toggleCollapse(target: string): void {

    this.collapsed[target] = !this.collapsed[target];
  }
  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }

}
