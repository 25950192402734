<nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow"  ngStickyNav>
  <div class="container mr-auto" style="margin-right: 1px;margin-left: 1px;">
    <a class="navbar-brand" routerLink="/Home">
      <img src="/assets/eft/iconografia/menu/EFT-BATTERYBOX.png" height="40" alt="Logo">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleClass()">
      <span class="burger-menu">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </span>
    </button>

    <div class="collapse navbar-collapse row"  id="navbarSupportedContent">
      <div class="col-6">
        <ul class="navbar-nav ml-auto " style="width: 100%;">
          <li class="nav-item col-3 text-right" [class.active]="currentSection === 'about'">
              <div>
                  <a [routerLink]="['/about']" [queryParams]="{ section: 'about' }" class="letra-bold link-btn texto-negro texto-secciones" (click)="changeSection('section1'); closeMenu()" style="font-size: smaller;" [innerHTML]="'ABOUT'|translate"></a>
              </div>
          </li>
          <li class="nav-item col-3 text-right" [class.active]="currentSection === 'products'">
              <div>
                  <a [routerLink]="['/products']" [queryParams]="{ section: 'products' }" class="letra-bold link-btn texto-negro texto-secciones" (click)="changeSection('section2'); closeMenu()" style="font-size: smaller;" [innerHTML]="'PRODUCTS'|translate"></a>
              </div>
          </li>
          <li class="nav-item col-3 text-right" [class.active]="currentSection === 'service'">
              <a [routerLink]="['/service']" [queryParams]="{ section: 'service' }" class="letra-bold link-btn texto-negro texto-secciones" (click)="changeSection('section3'); closeMenu()" style="font-size: smaller;" [innerHTML]="'SERVICE'|translate"></a>
          </li>
          <li class="nav-item col-3 text-right" [class.active]="currentSection === 'news'">
              <div>
                  <a [routerLink]="['/news']" [queryParams]="{ section: 'news' }" class="letra-bold link-btn texto-negro texto-secciones" (click)="changeSection('section5'); closeMenu()" style="font-size: smaller;" [innerHTML]="'NEWS'|translate"></a>
              </div>
          </li>
      </ul>
      
      </div>
     
      <div class="col-6" style="padding:0px">
        <ul class="navbar-nav ml-auto idioma" >
          <li class="menu-item" [class.active]="isProductsActive" (click)="closeSubMenuOnOutsideClick()">
            <div class="nav d-flex align-items-center row">
              <div class="col-4 idioma" >
                <img src="/assets/eft/iconografia/menu/LANGUAGE.svg">
            </div>
              <div class="col-8 idioma">
                <span class="nav-item letra-bold" style="font-size: smaller;">
                  {{ selectedLanguage ? getLanguageLabel(selectedLanguage) : getPreferredLanguageLabel() }}
                </span>
              </div>        
            </div>
          
            <ul class="submenu" [class.active]="isSubMenuOpen" (click)="onSubMenuClick($event)">
              <li style="padding-top: 0.5em;" *ngFor="let lang of languages">
                <a class="letra-bold" (click)="onSubMenuOptionClick(lang.code); closeMenu()" [class.selected]="selectedLanguage === lang.code">
                  {{ lang.label }}
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item" [class.active]="currentSection === 'downloads'">
            <div >
              <a [href]="['https://docs.eft-systems.de/documentos']"  class="letra-bold link-btn texto-negro texto-secciones" (click)="changeSection('section5'); closeMenu()" style="font-size: smaller;"[innerHTML]="'DOWNLOADS'|translate"></a>
            </div>
          </li>
          <li class="navbar-nav nav-items">
            <div >
              <a href="https://support.eft-systems.de/" target="_blank" class="letra-bold link-btn  texto-secciones" (click)="changeSection('section5'); closeMenu()" style="color:#DF280D;font-size: smaller;" [innerHTML]="'INSTALLER SIGN-IN'|translate"></a>
            </div>
          </li>
        </ul>
      </div>
      
      <ul class="navbar-nav ml-left for-responsive">
        <li class="nav-item"><span class="nav-link" routerLink="/about" (click)="closeMenu()"[innerHTML]="'ABOUT'|translate"></span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/products" (click)="closeMenu()"[innerHTML]="'PRODUCTS'|translate"></span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/service" (click)="closeMenu()"[innerHTML]="'SERVICE'|translate"></span></li>
        <!-- <li class="nav-item"><span class="nav-link" routerLink="/bepartner" (click)="closeMenu()"[innerHTML]="'BEPARTNER'|translate"></span></li> -->
        <li class="nav-item"><span class="nav-link" routerLink="/news" (click)="closeMenu()"[innerHTML]="'NEWS'|translate"></span></li>
        <li class="nav-item"><a class="nav-link"  [href]="['https://docs.eft-systems.de/documentos']" (click)="closeMenu()"[innerHTML]="'DOWNLOADS'|translate"></a></li>
        <li class="nav-item"><a class="nav-link" style="color: #DF280D;" href="https://support.eft-systems.de/" (click)="closeMenu()"[innerHTML]="'INSTALLER SIGN-IN'|translate"></a></li>
              <span>Language</span>
              <li><a class="letra-bold nav-link" (click)="onSubMenuOptionClick($event); switchToEnglish();closeMenu();">ENGLISH (UK/USA)</a></li>
              <li> <a style="padding-top: 0.5em;" class="letra-bold nav-link" (click)="onSubMenuOptionClick($event); switchToSpanish();closeMenu();">ESPAÑOL (ESPAÑA)</a></li>
              <li> <a class="letra-bold nav-link" (click)="onSubMenuOptionClick($event); switchToDeuch();closeMenu();">DEUTSCH (DEUTSCHLAND)</a></li>
             <li> <a class="letra-bold nav-link" (click)="onSubMenuOptionClick($event); closeMenu();switchToFr();closeMenu();">FRANÇAIS (FRANCE)</a></li>
             <li> <a class="letra-bold nav-link" (click)="onSubMenuOptionClick($event);closeMenu(); switchToIt();closeMenu();">ITALIANO (ITALIA)</a></li>
             <li> <a class="letra-bold nav-link" (click)="onSubMenuOptionClick($event);closeMenu(); switchToPo();closeMenu();">POLISH (POLAND)</a></li>
             
        </ul>

    </div>
  </div>
</nav>
