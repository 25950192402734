import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { OwlOptions } from 'ngx-owl-carousel-o'; // Asegúrate de importar OwlOptions desde la librería correcta
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-home-seven',
  templateUrl: './home-seven.component.html',
  styleUrls: ['./home-seven.component.scss']
})
export class HomeSevenComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller,private translate: TranslateService) {
    this.isSpanishLang = this.translate.currentLang === 'es';
    this.isEnglishLang = this.translate.currentLang === 'en';
    this.isDeutchLang = this.translate.currentLang === 'de';
    this.isItLang = this.translate.currentLang === 'it';
    this.isFrLang = this.translate.currentLang === 'fr';
  }
  @ViewChild('miVideo') videoElement: ElementRef;

  ngAfterViewInit() {
    // Accede al elemento de video y detén la reproducción
    this.videoElement.nativeElement.pause();
  }
  
  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }
  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.isSpanishLang = event.lang === 'es';
      this.isEnglishLang = event.lang === 'en';
      this.isDeutchLang = event.lang === 'de';
      this.isItLang = event.lang === 'it';
      this.isFrLang = event.lang === 'fr';
      
    });
    var video = document.getElementById('miVideo');

  }
  isSpanishLang: boolean;
  isEnglishLang: boolean;
  isDeutchLang: boolean;
  isItLang: boolean;
  isFrLang: boolean;
  isText1Visible: boolean = false;
  isText2Visible: boolean = false;
  isText3Visible: boolean = false;

  hideText1(): void {
    this.isText1Visible = false;
  }

  hideText2(): void {
    this.isText2Visible = false;
  }

  hideText3(): void {
    this.isText3Visible = false;
  }

  showText1(): void {
    this.isText1Visible = true;
  }

  showText2(): void {
    this.isText2Visible = true;
  }

  showText3(): void {
    this.isText3Visible = true;
  }

  carouselCards = [
    {
      imageSrc: '/assets/eft/fotos/HOME/Dominic Weber.png',
      quote: 'As an advantage for the installer I clearly see the easy installation [...] furthermore it is always important for the installer to have a good support in the background in case something happens.',
      author: 'Dominic Weber, Energie Freiamt AG'
    },
    {
      imageSrc: '/assets/eft/fotos/HOME/Installer1.jpg',
      quote: 'Our experience with BatteryBox has been consistently positive. Installation is relatively easy, complications actually never happen, and we have never had to make use of the corresponding service.',
      author: 'Daniel Lochmaier, Installer'
    },
    {
      imageSrc: '/assets/eft/fotos/HOME/Installer3.jpg',
      quote: 'The experience is very good. We have no installation problems, the installation is very easy, the design is very appealing to the customer and we actually have only positive feedback. Also, when it comes to future expansion, we can highly recommend it.',
      author: 'Josef Hainzinger, Stumbaum GMBH'
    }
  ];
  
  additionalCarouselCards = [
    {
      imageSrc: '/assets/eft/fotos/HOME/Installer2.jpg',
      quote: 'We use the BYD BatteryBox everywhere. There is no use case we can\'t cover. The guys at EFT are doing a great job. So, you can tell they live and breathe the BYD BatteryBox.',
      author: 'Karsten Schulz, Sailer Energy'
    },
    {
      imageSrc: '/assets/eft/fotos/HOME/Franco Bonutto.png',
      quote: 'BYD\'s system really won me over.',
      author: 'Franco Bonutto, Soltris GmbH'
    },
    {
      imageSrc: '/assets/eft/fotos/HOME/Michel Baumgartner.png',
      quote: 'Extremely quick to install',
      author: 'Michel Baumgartner, Posch & Partner AG'
    }
  ];
  
  showAdditionalCards: boolean = false;
  
  nextSlide() {
    if (!this.showAdditionalCards) {
      this.showAdditionalCards = true;
    }
  }
  
  prevSlide() {
    if (this.showAdditionalCards) {
      this.showAdditionalCards = false;
    }
  }
  carouselOptions: OwlOptions = {
    items: 3, // Muestra tres imágenes a la vez
    loop: true, // Habilita el bucle infinito
    margin: 10, // Espacio entre las imágenes
    nav: true, // Muestra controles de navegación
    navText: ['<span class="carousel-arrow">&#x2190;</span>', '<span class="carousel-arrow">&#x2192;</span>'], // Usar el símbolo "➔" en formato HTML para ambos botones
    
    responsive: {
      0: {
        items: 1 // En pantallas pequeñas, muestra una imagen a la vez
      },
      768: {
        items: 3 // En pantallas medianas y grandes, muestra tres imágenes a la vez
      }
    }
  };
}  