<div class="main-banner-white-service pb-70" style="height:auto">
    <div class=" pt-70 texto-centrado-h1" style="padding-left: 23%;padding-right: 23%;">
        <p class="text-left texto-negro mt-3 texto-chico" [innerHTML]="'  EFT-Systems GmbH <br><br>
        Bruchtannenstraße 28
        <br><br>
        63801 Kleinostheim
         <br><br>
         E-Mail info@eft-systems.de
         <br><br>
         Tel: +49 9352 8523999 Geschäftsführer: Juan Carlos Alonso
         Geschäftsführer: Juan CarlosAlonso <br><br><br><br>
        Registergericht: Amtsgericht Würzburg, HRB 12698 <br><br><br><br>
        Ust-IdNr: DE298989191 <br><br><br><br>
        <u><b> Haftung für Inhalte </b></u><br><br>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. <br><br><br><br>
        <u><b> Datenschutz </b></u> <br><br>
        Die Nutzung unserer Webseite ist in der Regel ohne eine Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adresse) erhoben werden, erfolgt dies – soweit es möglich ist– immer auf freiwilliger Basis. Wir geben Ihre Daten ohne Ihre ausdrückliche Zustimmung nicht an Dritte weiter. Außerdem weisen wir Sie darauf hin, dass die Datenübertragung im Internet (wie beispielsweise bei der Kommunikation über E-Mail) Sicherheitslücken aufweisen kann. Denn ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Wir widersprechen hiermit ausdrücklich der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien. Die Betreiber dieser Seiten behalten sich ausdrücklich vor, im Fall der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, rechtliche Schritte einzuleiten. <br><br>
        Gratis Impressum von agb.de'"></p>

    </div>
</div>