<a class="scroll-to-top hidden" (click)="scrollToTop()">
  <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-white-service" style="height:350px; background-color:#F8F8F8;">
  <div class=" pt-70 container" >
      <h1 class="text-start texto-negro titulo-chico" style="margin-top:5em;"[innerHTML]="'DOWNLOADS'|translate"></h1>
      <p class="text-start texto-negro mt-4 texto-chico" [innerHTML]="'Select the language and then download all the information about our products'|translate"></p>

  </div>
</div>
<div class="main-banner-white-home "style=" background-color:#F8F8F8">
  <div class="">
      <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="container-img text-center">
              <img src="/assets/eft/fotos/DOWNLOADS/HVS Frontal.jpg" height="80%"  alt="services-img"> 
          </div>
            <div class="services-content"style="background-color: #F8F8F8;height: auto">
                <div>
                  <h6 class="texto-negro mb-3 texto-chico"[innerHTML]="'PREMIUM HVS/HVM'|translate"></h6>
                </div>
                <div>
                  <div class="card-dow">
                    <div class=" d-flex justify-content-between" id="headingDatasheet" (click)="toggleCollapse('collapseDatasheet')" [attr.aria-expanded]="!collapsed['collapseDatasheet']" aria-controls="collapseDatasheet">
                      <h5 class="mb-0 ">
                        <a class="btn btn-nopadding text-start texto-chico"[innerHTML]="'DATASHEET'|translate">
                          
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseDatasheet'], 'fa-chevron-down': collapsed['collapseDatasheet']}"></i>
                      </span>
                    </div>
                    <div id="collapseDatasheet" class="" [ngClass]="{'collapse': collapsed['collapseDatasheet'], 'show': !collapsed['collapseDatasheet']}" aria-labelledby="headingDatasheet" style="background-color: #F8F8F8;">
                      <div class="">
                        <a [innerHTML]="'HVS/HVM Datasheet.pdf'|translate" class="text-crema" style="font-size: smaller;" style="font-size: smaller;"></a>
                      </div>
                      <div class="mb-1">
                        <a [innerHTML]="'HVS/HVM CombinerBox Datasheet'|translate" class="text-crema" style="font-size: smaller;"></a>
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingCompatibility"(click)="toggleCollapse('collapseCompatibility')" [attr.aria-expanded]="!collapsed['collapseCompatibility']" aria-controls="collapseCompatibility">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'COMPATIBILITY LIST'|translate">
                          
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseCompatibility'], 'fa-chevron-down': collapsed['collapseCompatibility']}"></i>
                      </span>
                    </div>
                    <div id="collapseCompatibility" [ngClass]="{'collapse': collapsed['collapseCompatibility'], 'show': !collapsed['collapseCompatibility']}" aria-labelledby="headingCompatibility"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a [innerHTML]="'HVS/HVM Compatibility List'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingservice"(click)="toggleCollapse('collapseservice')" [attr.aria-expanded]="!collapsed['collapseservice']" aria-controls="collapseservice">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'SERVICE MATERIALS'|translate">
                          
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice'], 'fa-chevron-down': collapsed['collapseservice']}"></i>
                      </span>
                    </div>
                    <div id="collapseservice" [ngClass]="{'collapse': collapsed['collapseservice'], 'show': !collapsed['collapseservice']}" aria-labelledby="headingservice"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a [innerHTML]="'HVS Service Guideline & Checklist'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                        <div class="mb-1">
                          <a href="https://drive.google.com/file/d/1_1GM8Ou-6F9H56MkSkV9T3V7LahSRdBj/view" target="_blank" class="text-crema" style="font-size: smaller;">Be Connect Plus</a>
                        </div>
                      </div>
                    </div>
                  </div>                  
                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingmanuals"(click)="toggleCollapse('collapsemanuals')" [attr.aria-expanded]="!collapsed['collapsemanuals']" aria-controls="collapsemanuals">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'MANUALS'|translate">
                          
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsemanuals'], 'fa-chevron-down': collapsed['collapsemanuals']}"></i>
                      </span>
                    </div>
                    <div id="collapsemanuals" [ngClass]="{'collapse': collapsed['collapsemanuals'], 'show': !collapsed['collapsemanuals']}" aria-labelledby="headingmanuals"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a [innerHTML]="'HVS/HVM Operating manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                        <div class="mb-1">
                          <a [innerHTML]="'HVS/HVM Quickstart guide'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                        <div class="mb-1">
                          <a [innerHTML]="'HVS/HVM Combiner box manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                        <div class="mb-1">
                          <a [innerHTML]="'Manual for BeConnect app'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                        <div class="mb-1">
                          <a [innerHTML]="'Charger manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                        </div>
                      </div>
                    </div>
                  </div>   

                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingwarranty"(click)="toggleCollapse('collapsewarranty')" [attr.aria-expanded]="!collapsed['collapsewarranty']" aria-controls="collapsewarranty">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'WARRANTY'|translate">
                          
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsewarranty'], 'fa-chevron-down': collapsed['collapsewarranty']}"></i>
                      </span>
                    </div>
                    <div id="collapsewarranty" [ngClass]="{'collapse': collapsed['collapsewarranty'], 'show': !collapsed['collapsewarranty']}" aria-labelledby="headingwarranty"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a [innerHTML]="'HVS/HVM Limited Warranty Europe'|translate" class="text-crema" style="font-size: smaller;">HVS/HVM Limited Warranty Europe</a>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>

        <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingcertificates"(click)="toggleCollapse('collapsecertificates')" [attr.aria-expanded]="!collapsed['collapsecertificates']" aria-controls="collapsecertificates">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'CERTIFICATES'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsecertificates'], 'fa-chevron-down': collapsed['collapsecertificates']}"></i>
                </span>
              </div>
              <div id="collapsecertificates" [ngClass]="{'collapse': collapsed['collapsecertificates'], 'show': !collapsed['collapsecertificates']}" aria-labelledby="headingcertificates"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/DOC-00074&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">HVS+HVM+Combiner box.zip</a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'HVS CE manufacturer self declaration'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00036&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Baden-Württemberg (PREMIUM 2021)</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00037&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Battery-Box Premium - NRW</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00038&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung Niedersachsen</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00039&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung MYK</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00040&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung_Komponenten Rheinland-Pfalz</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/10345694df&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Summary</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/2535a03f00&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Report</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/DOC-00125&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">IEC62619 - Austria - Propagation test</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/5be416b414&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">CE</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/6629664d20&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">IEC62040</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/7a7560d23a&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Certification for Safe Transport of Goods 2021(New carton)</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/836a0889df&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Certification for Safe Transport of Goods 2021(Old carton)</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/a250e95052&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">VDE2510</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/e621b87cad&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">MSDS 2021</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/2d899e09f2&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Combiner Box Certificates</a>
                  </div>
                </div>
              </div>
            </div>                                     
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services" >
            <div class="container-img text-center">
              <img src="/assets/eft/fotos/DOWNLOADS/LVL Frontal.jpg" height="80%"  alt="services-img"> 

          </div>
          <div class="services-content"style="background-color: #F8F8F8;height: auto">
          <div>
            <h6 class="texto-negro mb-3"[innerHTML]="'PREMIUM LVL'|translate"></h6>
          </div>
          <div>
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingDatasheet"(click)="toggleCollapse('collapseDatasheet3')" [attr.aria-expanded]="!collapsed['collapseDatasheet3']" aria-controls="collapseDatasheet3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start"[innerHTML]="'DATASHEET'|translate" >
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseDatasheet3'], 'fa-chevron-down': collapsed['collapseDatasheet3']}"></i>
                </span>
              </div>
              <div id="collapseDatasheet3" [ngClass]="{'collapse': collapsed['collapseDatasheet3'], 'show': !collapsed['collapseDatasheet3']}" aria-labelledby="headingDatasheet"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVL (2021) Datasheet'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Datasheet'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingCompatibility"(click)="toggleCollapse('collapseCompatibility3')" [attr.aria-expanded]="!collapsed['collapseCompatibility3']" aria-controls="collapseCompatibility3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'COMPATIBILITY LIST'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseCompatibility3'], 'fa-chevron-down': collapsed['collapseCompatibility3']}"></i>
                </span>
              </div>
              <div id="collapseCompatibility3" [ngClass]="{'collapse': collapsed['collapseCompatibility3'], 'show': !collapsed['collapseCompatibility3']}" aria-labelledby="headingCompatibility"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Compatibility list'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingservice"(click)="toggleCollapse('collapseservice3')" [attr.aria-expanded]="!collapsed['collapseservice3']" aria-controls="collapseservice3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'SERVICE MATERIALS'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice3'], 'fa-chevron-down': collapsed['collapseservice3']}"></i>
                </span>
              </div>
              <div id="collapseservice3" [ngClass]="{'collapse': collapsed['collapseservice3'], 'show': !collapsed['collapseservice3']}" aria-labelledby="headingservice"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Service Guideline & Checklist'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a href="https://drive.google.com/file/d/1_1GM8Ou-6F9H56MkSkV9T3V7LahSRdBj/view" target="_blank" class="text-crema" style="font-size: smaller;">Be Connect Plus</a>
                  </div>
                </div>
              </div>
            </div>                  
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingmanuals"(click)="toggleCollapse('collapsemanuals3')" [attr.aria-expanded]="!collapsed['collapsemanuals3']" aria-controls="collapsemanuals3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'MANUALS'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsemanuals3'], 'fa-chevron-down': collapsed['collapsemanuals3']}"></i>
                </span>
              </div>
              <div id="collapsemanuals3" [ngClass]="{'collapse': collapsed['collapsemanuals3'], 'show': !collapsed['collapsemanuals3']}" aria-labelledby="headingmanuals"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVL 2021 Operating manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL 2021 Quick Start Guide'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Quick Start Guide'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LV BMU IP55 manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Manual for BeConnect app'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Charger manual'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                </div>
              </div>
            </div>   

            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingwarranty"(click)="toggleCollapse('collapsewarranty3')" [attr.aria-expanded]="!collapsed['collapsewarranty3']" aria-controls="collapsewarranty3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'WARRANTY'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsewarranty3'], 'fa-chevron-down': collapsed['collapsewarranty3']}"></i>
                </span>
              </div>
              <div id="collapsewarranty3" [ngClass]="{'collapse': collapsed['collapsewarranty3'], 'show': !collapsed['collapsewarranty3']}" aria-labelledby="headingwarranty"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Limited Warranty residential Europe'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVL Limited Warranty commercial Europe'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                </div>
              </div>
            </div>                  
          </div>

            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingcertificates"(click)="toggleCollapse('collapsecertificates3')" [attr.aria-expanded]="!collapsed['collapsecertificates3']" aria-controls="collapsecertificates3">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'CERTIFICATES'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsecertificates3'], 'fa-chevron-down': collapsed['collapsecertificates3']}"></i>
                </span>
              </div>
              <div id="collapsecertificates3" [ngClass]="{'collapse': collapsed['collapsecertificates3'], 'show': !collapsed['collapsecertificates3']}" aria-labelledby="headingcertificates"style="background-color: #F8F8F8;">
                <div class="mb-1">
    
                  <div class="mb-1">
                    <a [innerHTML]="'LVL CE manufacturer self declaration'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00036&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Baden-Württemberg (PREMIUM 2021)</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00037&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Battery-Box Premium - NRW</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00038&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung Niedersachsen</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00039&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung MYK</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00040&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung_Komponenten Rheinland-Pfalz</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - IEC62619.pdf" target="_blank" class="text-crema" style="font-size: smaller;">IEC62619</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - IEC62040.pdf" target="_blank" class="text-crema" style="font-size: smaller;">IEC62040</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - UN38.3 Test Summary.pdf" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Summary</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - UN38.3 Test Report2020.pdf" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Report</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - CE TUV.pdf" target="_blank" class="text-crema" style="font-size: smaller;">CE TUV</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - MSDS 2021.pdf" target="_blank" class="text-crema" style="font-size: smaller;">MSDS 2021</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVL - Certification for Safe Transport of Goods 2021.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Certification for Safe Transport of Goods 2021</a>
                  </div>
                </div>
              </div>
            </div>                                  
      </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="container-img text-center">
              <img src="/assets/eft/fotos/DOWNLOADS/LVS Frontal.jpg" height="80%"  alt="services-img"> 
          </div>
          <div class="services-content"style="background-color: #F8F8F8;height: auto">
          <div>
            <h6 class="texto-negro mb-3"[innerHTML]="'PREMIUM LVS'|translate"></h6>
          </div>
          <div>
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingDatasheet"(click)="toggleCollapse('collapseDatasheet2')" [attr.aria-expanded]="!collapsed['collapseDatasheet2']" aria-controls="collapseDatasheet2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'DATASHEET'|translate">
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseDatasheet2'], 'fa-chevron-down': collapsed['collapseDatasheet2']}"></i>
                </span>
              </div>
              <div id="collapseDatasheet2" [ngClass]="{'collapse': collapsed['collapseDatasheet2'], 'show': !collapsed['collapseDatasheet2']}" aria-labelledby="headingDatasheet"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVS Datasheet'|translate" class="text-crema" style="font-size: smaller;">LVS Datasheet</a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingCompatibility"(click)="toggleCollapse('collapseCompatibility2')" [attr.aria-expanded]="!collapsed['collapseCompatibility2']" aria-controls="collapseCompatibility2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start"[innerHTML]="'COMPATIBILITY LIST'|translate" >
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseCompatibility2'], 'fa-chevron-down': collapsed['collapseCompatibility2']}"></i>
                </span>
              </div>
              <div id="collapseCompatibility2" [ngClass]="{'collapse': collapsed['collapseCompatibility2'], 'show': !collapsed['collapseCompatibility2']}" aria-labelledby="headingCompatibility"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a  href="/assets/pdf/240116_BYD_Battery-Box_Premium_LVS_Minimum_Configuration_List_V1.11-65a73c0df09bc (1).pdf" target="_blank" class="text-crema" style="font-size: smaller;">LVS Compatibility list</a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingservice"(click)="toggleCollapse('collapseservice2')" [attr.aria-expanded]="!collapsed['collapseservice2']" aria-controls="collapseservice2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'SERVICE MATERIALS'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice2'], 'fa-chevron-down': collapsed['collapseservice2']}"></i>
                </span>
              </div>
              <div id="collapseservice2" [ngClass]="{'collapse': collapsed['collapseservice2'], 'show': !collapsed['collapseservice2']}" aria-labelledby="headingservice"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVS Service Guideline & Checklist'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a href="https://drive.google.com/file/d/1_1GM8Ou-6F9H56MkSkV9T3V7LahSRdBj/view" target="_blank" class="text-crema" style="font-size: smaller;">Be Connect Plus</a>
                  </div>
                </div>
              </div>
            </div>                  
            
            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingmanuals"(click)="toggleCollapse('collapsemanuals2')" [attr.aria-expanded]="!collapsed['collapsemanuals2']" aria-controls="collapsemanuals2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'MANUALS'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsemanuals2'], 'fa-chevron-down': collapsed['collapsemanuals2']}"></i>
                </span>
              </div>
              <div id="collapsemanuals2" [ngClass]="{'collapse': collapsed['collapsemanuals2'], 'show': !collapsed['collapsemanuals2']}" aria-labelledby="headingmanuals"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVS Operating manuals'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a  [innerHTML]="'LVS Quickstart guide'|translate" class="text-crema" style="font-size: smaller;"></a>
                  </div>
                  <div class="mb-1">
                    <a  [innerHTML]="'LV BMU IP55 manual'|translate" class="text-crema" style="font-size: smaller;">LV BMU IP55 manual</a>
                  </div>
                  <div class="mb-1">
                    <a  [innerHTML]="'Manual for BeConnect app'|translate" class="text-crema" style="font-size: smaller;">Manual for BeConnect app</a>
                  </div>
                  <div class="mb-1">
                    <a  [innerHTML]="'Charger manual'|translate" class="text-crema" style="font-size: smaller;">Charger manual</a>
                  </div>
                </div>
              </div>
            </div>   

            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingwarranty"(click)="toggleCollapse('collapsewarranty2')" [attr.aria-expanded]="!collapsed['collapsewarranty2']" aria-controls="collapsewarranty2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start"[innerHTML]="'WARRANTY'|translate" >
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsewarranty2'], 'fa-chevron-down': collapsed['collapsewarranty2']}"></i>
                </span>
              </div>
              <div id="collapsewarranty2" [ngClass]="{'collapse': collapsed['collapsewarranty2'], 'show': !collapsed['collapsewarranty2']}" aria-labelledby="headingwarranty"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a [innerHTML]="'LVS Limited Warranty residential Europe'|translate" class="text-crema" style="font-size: smaller;">LVS Limited Warranty residential Europe</a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'LVS Limited Warranty commercial'|translate" class="text-crema" style="font-size: smaller;">LVS Limited Warranty commercial</a>
                  </div>
                </div>
              </div>
            </div>                  
          </div>

            <div class="card-dow">
              <div class="d-flex justify-content-between" id="headingcertificates"(click)="toggleCollapse('collapsecertificates2')" [attr.aria-expanded]="!collapsed['collapsecertificates2']" aria-controls="collapsecertificates2">
                <h5 class="mb-0">
                  <a class="btn btn-nopadding text-start" [innerHTML]="'CERTIFICATES'|translate">
                    
                  </a>
                </h5>
                <span>
                  <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapsecertificates2'], 'fa-chevron-down': collapsed['collapsecertificates2']}"></i>
                </span>
              </div>
              <div id="collapsecertificates2" [ngClass]="{'collapse': collapsed['collapsecertificates2'], 'show': !collapsed['collapsecertificates2']}" aria-labelledby="headingcertificates"style="background-color: #F8F8F8;">
                <div class="mb-1">
                  <div class="mb-1">
                    <a  [innerHTML]="'LVS.zip'|translate" class="text-crema" style="font-size: smaller;">LVS.zip</a>
                  </div>
                  <div class="mb-1">
                    <a [innerHTML]="'CE manufacturer self declaration'|translate" class="text-crema" style="font-size: smaller;">CE manufacturer self declaration</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00036&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Baden-Württemberg (PREMIUM 2021)</a>
                  </div>
                  <div class="mb-1" *ngIf="isDeutchLang">
                    <a href="https://docs.eft-systems.de/download/DOC-00037&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Herstellererklärung Battery-Box Premium - NRW</a>
                  </div>
                <div class="mb-1" *ngIf="isDeutchLang">
                  <a href="https://docs.eft-systems.de/download/DOC-00038&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung Niedersachsen</a>
                </div>
                <div class="mb-1" *ngIf="isDeutchLang">
                  <a href="https://docs.eft-systems.de/download/DOC-00039&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung MYK</a>
                </div>
                <div class="mb-1" *ngIf="isDeutchLang">
                  <a href="https://docs.eft-systems.de/download/DOC-00040&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">BYD Herstellererklärung_Komponenten Rheinland-Pfalz</a>
                </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - IEC62619.pdf" target="_blank" class="text-crema" style="font-size: smaller;">IEC62619</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - IEC62040.pdf" target="_blank" class="text-crema" style="font-size: smaller;">IEC62040</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - UN38.3 Test Summary.pdf" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Summary</a>
                  </div>
                  <div class="mb-1">
                    <a href="https://docs.eft-systems.de/download/f89e9e86f6&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">UN38.3 Test Report</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - CE TUV.pdf" target="_blank" class="text-crema" style="font-size: smaller;">CE</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - MSDS.pdf" target="_blank" class="text-crema" style="font-size: smaller;">MSDS 2021</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - Certification for Safe Transport of Goods 2021-New Carton  - for Products with production date April-2021.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Certification for Safe Transport of Goods 2021(New Carton)</a>
                  </div>
                  <div class="mb-1">
                    <a href="assets/pdf/LVS - Certification for Safe Transport of Goods 2021-Old Carton.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Certification for Safe Transport of Goods 2021(Old Carton)</a>
                  </div>
                </div>
              </div>
            </div>                                  
      </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div >
  </div>
</div>









<div class="main-banner-white-home "style=" background-color:#F8F8F8">
  <div class="">
      <div class="container">
      <div class="row justify-content-right">
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="services-content"style="background-color: #F8F8F8;height: auto">
                <div>
                  <h6 class="texto-negro mb-3"[innerHTML]="'OTHER DOCUMENTS'|translate"></h6>
                </div>
                <div>
                  <div class="card-dow">
                    <div class=" d-flex justify-content-between" id="headingDatasheet">
                      <h5 class="mb-0">
                        <a href="assets/pdf/BYD_Battery-Box_Privacy_Policy.pdf" target="_blank" class="btn btn-nopadding text-start"  [innerHTML]="'PRIVACY'|translate"></a>
                      </h5>
                    </div>
                  </div>
                  
                  <div class="card-dow">
                    <div class=" d-flex justify-content-between" id="headingDatasheet">
                      <h5 class="mb-0">
                        <a href="assets/pdf/Battery-Box_Premium_Packaging_20220322_V1.9.xlsx" target="_blank" class="btn btn-nopadding text-start" [innerHTML]="'LOGISTICS DATA'|translate"></a>
                      </h5>
                    </div>
                  </div>
                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingservice"(click)="toggleCollapse('collapseservice')" [attr.aria-expanded]="!collapsed['collapseservice']" aria-controls="collapseservice">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'MARKETING MATERIALS'|translate">
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice'], 'fa-chevron-down': collapsed['collapseservice']}"></i>
                      </span>
                    </div>
                    <div id="collapseservice" [ngClass]="{'collapse': collapsed['collapseservice'], 'show': !collapsed['collapseservice']}" aria-labelledby="headingservice"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a href="assets/pdf/2022_BYD_Group_New_LOGO.zip" target="_blank" class="text-crema" style="font-size: smaller;">BYD logo</a>
                        </div>
                        <div class="mb-1">
                          <a href="assets/eft/HIGH RES RENDERS BatteryBox.zip" target="_blank" class="text-crema" style="font-size: smaller;">Pictures</a>
                        </div>
                      </div>
                    </div>
                  </div>                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingservice5"(click)="toggleCollapse('collapseservice5')" [attr.aria-expanded]="!collapsed['collapseservice5']" aria-controls="collapseservice5">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'PRO 2.5-10.0'">
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice5'], 'fa-chevron-down': collapsed['collapseservice5']}"></i>
                      </span>
                    </div>
                    <div id="collapseservice5" [ngClass]="{'collapse': collapsed['collapseservice5'], 'show': !collapsed['collapseservice5']}" aria-labelledby="headingservice5"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a href="https://docs.eft-systems.de/download/203046c195&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Pro 2.5-10.0 Compatibility list</a>
                        </div>
                        <div class="mb-1">
                          <a href="https://docs.eft-systems.de/download/f242dfcb92&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Pro 2.5-10.0 Quick Reference Guide</a>
                        </div>
                        <div class="mb-1">
                          <a class="text-crema" style="font-size: smaller;" [innerHTML]="'Pro 2.5-10.0 Service Guideline'|translate"></a>
                        </div>
                        <!-- <div class="mb-1" *ngIf="isDeutchLang">
                          <a class="text-crema" style="font-size: smaller;" [innerHTML]="'Pro 2.5-10.10 Warranty Germany'|translate"></a>
                        </div> -->
                        <div class="mb-1"  *ngIf="isDeutchLang ">
                          <a href="/assets/pdf/2020-02-25 Erweiterungsanfrage Anleitung DE.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Erweiterungsanfrage</a>
                        </div>
                        <div class="mb-1"  *ngIf="!isDeutchLang && !isEsLang && !isItLang">
                          <a href="/assets/pdf/2020-02-25%20Extension%20Request%20Guide%20EN.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isEsLang">
                          <a href="/assets/pdf/2020-03-09 Extension Request Guide ES.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isItLang">
                          <a href="/assets/pdf/2020-03-30 Extension Request Guide IT (2).pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                      </div>
                    </div>
                  </div>                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingservice4"(click)="toggleCollapse('collapseservice4')" [attr.aria-expanded]="!collapsed['collapseservice4']" aria-controls="collapseservice5">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'PRO 13.8'">
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice4'], 'fa-chevron-down': collapsed['collapseservice4']}"></i>
                      </span>
                    </div>
                    <div id="collapseservice4" [ngClass]="{'collapse': collapsed['collapseservice4'], 'show': !collapsed['collapseservice4']}" aria-labelledby="headingservice4"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a href="https://docs.eft-systems.de/download/60515c1784&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Compatibility list</a>
                        </div>
                        <div class="mb-1">
                          <a href="https://docs.eft-systems.de/download/8b38fc662a&_lang=en" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Quick Reference Guide</a>
                        </div>
                        <div class="mb-1">
                          <a class="text-crema" style="font-size: smaller;" [innerHTML]="'Pro 13.8 Service Guideline'|translate"></a>
                        </div>
                        <!-- <div class="mb-1" *ngIf="isDeutchLang">
                          <a class="text-crema" style="font-size: smaller;" [innerHTML]="'Pro 2.5-10.10 Warranty Germany'|translate"></a>
                        </div> -->
                        <div class="mb-1"  *ngIf="isDeutchLang ">
                          <a href="/assets/pdf/2020-02-25 Erweiterungsanfrage Anleitung DE.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Erweiterungsanfrage</a>
                        </div>
                        <div class="mb-1"  *ngIf="!isDeutchLang && !isEsLang && !isItLang">
                          <a href="/assets/pdf/2020-02-25%20Extension%20Request%20Guide%20EN.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isEsLang">
                          <a href="/assets/pdf/2020-03-09 Extension Request Guide ES.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isItLang">
                          <a href="/assets/pdf/2020-03-30 Extension Request Guide IT (2).pdf" target="_blank" class="text-crema" style="font-size: smaller;">Pro 13.8 Extension request</a>
                        </div>
                       
                      </div>
                    </div>
                  </div>                  
                  <div class="card-dow">
                    <div class="d-flex justify-content-between" id="headingservice6"(click)="toggleCollapse('collapseservice6')" [attr.aria-expanded]="!collapsed['collapseservice6']" aria-controls="collapseservice6">
                      <h5 class="mb-0">
                        <a class="btn btn-nopadding text-start" [innerHTML]="'LV & HV Old'">
                        </a>
                      </h5>
                      <span>
                        <i class="fas" [ngClass]="{'fa-chevron-up': !collapsed['collapseservice6'], 'fa-chevron-down': collapsed['collapseservice6']}"></i>
                      </span>
                    </div>
                    <div id="collapseservice6" [ngClass]="{'collapse': collapsed['collapseservice6'], 'show': !collapsed['collapseservice6']}" aria-labelledby="headingservice6"style="background-color: #F8F8F8;">
                      <div class="mb-1">
                        <div class="mb-1">
                          <a href="/assets/pdf/2020-01-29 Software Package LV.zip" target="_blank" class="text-crema" style="font-size: smaller;">Software LV</a>
                        </div>
                        <div class="mb-1">
                          <a href="/assets/pdf/Battery-Box HV Software V3.013C.zip" target="_blank" class="text-crema" style="font-size: smaller;">Firmware HV V3.013C</a>
                        </div>
                        <div class="mb-1"  *ngIf="isDeutchLang ">
                          <a href="/assets/pdf/2020-02-25 Erweiterungsanfrage Anleitung DE.pdf" target="_blank" class="text-crema" style="font-size: smaller;"> Erweiterungsanfrage</a>
                        </div>
                        <div class="mb-1"  *ngIf="!isDeutchLang && !isEsLang && !isItLang">
                          <a href="/assets/pdf/2020-02-25%20Extension%20Request%20Guide%20EN.pdf" target="_blank" class="text-crema" style="font-size: smaller;"> Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isEsLang">
                          <a href="/assets/pdf/2020-03-09 Extension Request Guide ES.pdf" target="_blank" class="text-crema" style="font-size: smaller;">Extension request</a>
                        </div>
                        <div class="mb-1"  *ngIf="isItLang">
                          <a href="/assets/pdf/2020-03-30 Extension Request Guide IT (2).pdf" target="_blank" class="text-crema" style="font-size: smaller;"> Extension request</a>
                        </div>
                       
                      </div>
                    </div>
                  </div>                  