import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/BydBatteryBox/home-one.component';
import { HomeTwoComponent } from './components/RaiOn/home-two.component';
import { HomeThreeComponent } from './components/AboutUs/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/WhereToBuy/home-five.component';
import { HomeSixComponent } from './components/Contact/home-six.component';
import { HomeSevenComponent } from './components/Home/home-seven.component';
import { HomeNineComponent } from './components/OnlineServiceCenter/home-nine.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { PoliticasComponent } from './components/politicas/politicas.component';

const routes: Routes = [
    {path: '', component: HomeSevenComponent},
    { path: 'raion', component: HomeTwoComponent },
    { path: 'about', component: HomeThreeComponent },
    {path: 'home-four', component: HomeFourComponent},
    {path: 'news', component: HomeSixComponent},
    {path: 'Home', component: HomeSevenComponent},
    {path: 'login', component: HomeNineComponent},
    {path: 'products', component: HomeElevenComponent},
    {path: 'service', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    {path: 'trainings', component: BlogDetailsComponent},
    {path: 'politicas', component: PoliticasComponent},

    {path: '**', component: HomeSevenComponent} 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }