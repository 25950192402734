
<div style="background-color: #F8F8F8;">


<a class="scroll-to-top hidden" (click)="scrollToTop()">
  <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-white-about ptb-70" style="height:560px">
  <div class=" pt-70 pb-70 texto-centrado-h1">
      <h1 class=" texto-negro titulo-chico" style="margin-top:3em"[innerHTML]="'WE ARE EFT AND WE ARE THERE FOR YOU'|translate"></h1>
      <p class="text-center texto-negro mt-4 texto-chico"[innerHTML]="'texto11'|translate"></p>
      <br>
  </div>
  <div >
  </div>
</div>
<div [ngClass]="{'nav-about': true, 'nav-fixed': isNavFixed}">
  <p class="hover"><a class="texto-negro letra-bold texto-chico" (click)="onClick('eft2')">EFT SYSTEMS</a></p>
  <p class="hover"><a class="texto-negro letra-bold texto-chico" (click)="onClick('battery')">BATTERYBOX</a></p>
  <p class="hover"><a class="texto-negro letra-bold texto-chico" (click)="onClick('byd')">BYD</a></p>
</div>
<div class="main-banner" style="height: 750px;" id="eft2">
  <div class="row" style="height: 600px;">
      <div class="col-lg-6 col-md-12 mt-5 padding-about" style="padding-top: 12%;">
          <div class="container">
              <div class="d-flex flex-column align-items-start justify-content-center h-100 ms-5">
                  <h1 class="text-start texto-blanco " style="font-size:30px"[innerHTML]="'texto12'|translate"></h1><br>
                  <div class="container">
                    <ul class="dot-list">
                      <li class="texto-blanco texto-chico"[innerHTML]="'Covering all of Europe with local teams in multiple languages'|translate">
                          
                      </li>
                      <li class="texto-blanco texto-chico"[innerHTML]="'One contact for any purpose, from training, through installation and service to end-of-life'|translate">
                          
                      </li>
                  </ul>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-6 col-md-12">
          <img src="/assets/eft/fotos/ABOUT/About1.jpg" alt="">
      </div>
  </div>
</div>


        <div class="pt-70" style="background-color:#FFFFFF; padding-bottom:20px;">
          <div class="container">
            <div style=" padding-top:6em; padding-bottom:2em">
              <p class="texto-chico"[innerHTML]="'Our work for installers at EFT-Systems:'|translate"></p>
            </div>
              
            <div class="row " style="width:100%;background-color:#FFFFFF;" >
              <div class="col-md-3 mb-3">
                  <div class="card-container" style="height: 100%;">
                      <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em; ">
                          <img src="/assets/eft/iconografia/about/SVG/trainings.svg" height="50" alt="services-img">
                          <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3 letra-bold"[innerHTML]="'TRAININGS'|translate"></h1>
                          <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto13'|translate">
                      </div>
  
                  </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="card-container">
                  <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em; ">
                    <img src="/assets/eft/iconografia/about/SVG/info.svg" height="50" alt="services-img">
                        <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3"[innerHTML]="'PRODUCT INFORMATION'|translate"></h1>
                        <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto14'|translate"></p>
                    </div>
  
                </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card-container">
                <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em;">
                  <img src="/assets/eft/iconografia/about/SVG/endoflife.svg" height="50" alt="services-img">
                      <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3"[innerHTML]="'END OF LIFE'|translate"></h1>
                      <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto15'|translate"></p>
                  </div>
  
              </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="card-container">
              <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em;">
                <img src="/assets/eft/iconografia/about/SVG/support.svg" height="50" alt="services-img">
                    <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3"[innerHTML]="'TECHNICAL SUPPORT'|translate"></h1>
                    <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto16'|translate"></p>
                </div>
  
            </div>
        </div>
          </div>
            <div class="row " style="background-color:#FFFFFF;width:100%;">
              <div class="col-md-3 mb-3">
                  <div class="card-container">
                    <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em;">
                      <img src="/assets/eft/iconografia/about/SVG/aftersales.svg" height="50" alt="services-img">
                          <h1 style="font-size: smaller;padding-top: 4em" class="text-start mb-3"[innerHTML]="'AFTER SALES SERVICE'|translate"></h1>
                          <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto17'|translate"></p>
                      </div>
  
                  </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="card-container">
                  <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em;padding-top:1em;">
                    <img src="/assets/eft/iconografia/about/SVG/logistics.svg" height="50" alt="services-img">
                        <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3 "[innerHTML]="'LOGISTICS'|translate"></h1>
                        <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto18'|translate"></p>
                    </div>
  
                </div>
            </div>
            <div class="col-md-3 mb-3">
              <div class="card-container">
                <div class="icon-border card-about card-height" style="vertical-align: top;padding-left: 1em; padding-top:1em;padding-bottom:1.5em;">
                  <img src="/assets/eft/iconografia/about/SVG/repair.svg" height="50" alt="services-img">
                      <h1 style="font-size: smaller;padding-top: 5em" class="text-start mb-3"[innerHTML]="'REPAIR'|translate"></h1>
                      <p style="font-size: smaller;" class="texto-chico"[innerHTML]="'texto19'|translate"></p>
                  </div>
  
              </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="card-container">
              <div class="icon-border card-height" style="vertical-align: top;padding-left: 1em; padding-top:1em;">
                <img src="/assets/eft/iconografia/about/SVG/lab.svg" height="50" alt="services-img">
                    <h1 style="font-size: smaller;padding-top: 3.8em" class="text-start mb-3 pad-card"[innerHTML]="'LAB'|translate"></h1>
                    <p style="font-size: smaller;"class="texto-chico"[innerHTML]="'texto20'|translate"></p>
                </div>
  
            </div>
        </div>
          </div>
          </div>

      </div>
      <div class="main-banner-about-img container" style="background-color:#F8F8F8">
          <img src="/assets/eft/fotos/ABOUT/Team.jpg" alt="" width="100%">

        </div>

        <div class="text-start  ptb-100" style="background-color: #F0F0F0;" id="battery">
          <div class="container ">
                <div class=" pt-70 ">
                  <h1 class="mt-5 mb-5 texto-negro text-start titulo-chico" [innerHTML]="'ABOUT BATTERYBOX'|translate"></h1>
                  <p class="text-start texto-negro texto-chico" style="padding-right:35%"[innerHTML]="'texto21'|translate"></p>
                  <br>
              </div>
              <div >
              </div>
            </div>
          </div>
          <div class="main-banner-battery" style="background-color:#ffffff" >
            <div class="row no-gutter" >
                <div class="col-lg-6 col-md-12 " style=" padding-top: 12%;">
                    <div class="container ms-5 cont-resp">
                        <h1 class="text-start texto-negro titulo-chico" [innerHTML]="'texto22'|translate"></h1><br>
                        <p class="texto-negro texto-chico"[innerHTML]="'texto23'|translate"></p><br>
                        <h6 class="letra-bold mb-2 texto-grande"[innerHTML]="'TOTAL CONTROL'|translate"></h6>
                        <p class="texto-negro texto-chico"[innerHTML]="'texto24'|translate"></p><br>
                        <h6 class="letra-bold mb-2 texto-grande" style="font-size: small;"[innerHTML]="'EFFICIENCY'|translate"></h6>
                        <p class="texto-negro texto-chico"[innerHTML]="'Top results in independent efficiency tests'|translate"></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 ">
                    <img src="/assets/eft/fotos/ABOUT/About2.jpg" alt="">
                </div>
            </div>
        </div>
          <div class="main-banner-battery" style="background-color:#ffffff" >
            <div class="row no-gutter" >
                <div class="col-lg-6 col-md-12 text-end">
                    <img src="/assets/eft/fotos/ABOUT/About3.jpg" alt="">
                </div>
                <div class="col-lg-6 col-md-12 " style="padding-top: 12%;">
                  <div class="container ms-5 cont-resp">
                      <h1 class="text-start texto-negro titulo-chico"[innerHTML]="'texto25'|translate"></h1><br>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto26'|translate"></p><br>
                      <h6 class="letra-bold mb-2 texto-grande"style="font-size: small;"[innerHTML]="'SAFE'|translate"></h6>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto27'|translate"></p><br>
                      <h6 class="letra-bold mb-2 texto-grande" [innerHTML]="'INSTALLED WORLDWIDE'|translate"></h6>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto28'|translate"></p>
                  </div>
              </div>
            </div>
        </div>
        <div class="main-banner-battery" style="background-color:#ffffff" >
          <div class="row no-gutter" >
              <div class="col-lg-6 col-md-12 " style=" padding-top: 12%;">
                  <div class="container ms-5 cont-resp">
                      <h1 class="text-start padding-top texto-negro titulo-chico"[innerHTML]="'texto29'|translate" ></h1><br>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto30'|translate"></p><br>
                      <h6 class="letra-bold texto-grande mb-2"style="font-size: small;"[innerHTML]="'FROM 4 KWH TO 983 MWH'|translate"></h6>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto31'|translate"></p><br>
                      <h6 class="letra-bold texto-grande mb-2" style="font-size: small;"[innerHTML]="'BEST COMPONENT COMBINATION'|translate"></h6>
                      <p class="texto-negro texto-chico"[innerHTML]="'texto32'|translate"></p>
                  </div>
              </div>
              <div class="col-lg-6 col-md-12 ">
                  <img src="/assets/eft/fotos/ABOUT/About4.jpg" alt="">
              </div>
          </div>
      </div>
      <div class="text-start byd-about" style="background-color: #000000; " >
        <div class="container">
        <div class="container-about ">
              <div class=" pt-70 "id="byd">
                <h1 class="mt-5 mb-3 texto-blanco text-start titulo-chico"[innerHTML]="'ABOUT BYD'|translate"  >ABOUT BYD</h1 >
                <p class="text-start texto-blanco texto-chico" style=" padding-right:40%"[innerHTML]="'texto33'|translate">
                 </p>
                <br>
            </div>
            <div class="" >
              <div class="row no-gutter text-start">
                <div class="col-lg-3 col-md-6 pb-2">
                  <img class="pading-about img-espacio" src="/assets/eft/fotos/ABOUT/BYD1.jpg" alt="">
                </div>
                <div class="col-lg-3 col-md-6 pb-2">
                  <img class="pading-about img-espacio" src="/assets/eft/fotos/ABOUT/BYD2.jpg" alt="">
                </div>
                <div class="col-lg-3 col-md-6 pb-2">
                  <img class="pading-about img-espacio" src= "/assets/eft/fotos/ABOUT/BYD3.jpg" alt="">
                </div>
                <div class="col-lg-3 col-md-6 pb-2">
                  <img class="pading-about img-espacio" src="/assets/eft/fotos/ABOUT/BYD4.jpg" alt="">
                </div>
              </div>
            </div> <br>
            <p class="text-left texto-blanco texto-grande d-flex justify-content-center" style="padding-top:5em"[innerHTML]="'texto34'|translate">
              </p>
            <div class="ms-5">
              <div class="" style="padding-top: 3em;">
              <div class="row justify-content-center"  >
                <div class="col-3 cuadrado-about d-flex flex-column align-items-center">
                  <h1 class="mb-3"><span style="color: #DF280D;">400 +</span></h1>
                  <h6 class="texto-blanco text-center"[innerHTML]="'CITIES'|translate"></h6>
                </div>
                
                <div class="col-3 cuadrado-about">
                  <h1 class="" ><span style="color:#DF280D;" >70 +</span></h1>
                  <h6 class="texto-blanco text-center"[innerHTML]="'COUNTRIES'|translate"></h6>
                </div>
                <div class="col-3 cuadrado-about">
                  <h1 class="" ><span style="color:#DF280D;" >6</span></h1>
                  <h6 class="texto-blanco text-center"[innerHTML]="'CONTINENTS'|translate"></h6>
                </div>
            </div>
          </div>
            </div>
          </div>
        </div>
        </div>
        <div class=" text-center pt-5 pb-5" style="background-color: #000000; height:600px">
          <div class="container-about">
              <div *ngIf="!isSpanishLang && !isDeutchLang && !isItLang && !isFrLang" class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/qEarWtYyFU8" allowfullscreen height="500px" width="70%" ></iframe>
              </div>
              <div *ngIf="isSpanishLang" class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/xHwgFQ47SOo" allowfullscreen height="500px" width="70%" ></iframe>
              </div>
              <div *ngIf="isDeutchLang" class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/EO3buSJmD5s" allowfullscreen height="500px" width="70%" ></iframe>
              </div>
              <div *ngIf="isItLang" class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/SCCCh6w_XnA" allowfullscreen height="500px" width="70%" ></iframe>
              </div>
              <div *ngIf="isFrLang" class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/qEarWtYyFU8" allowfullscreen height="500px" width="70%" ></iframe>
              </div>
          </div>
      </div>

    
      
  </div>