import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
  isNavFixed: boolean = false; // Para controlar si la barra de navegación está fija
  passedPosition: boolean = false; // Para controlar si se ha pasado por la posición deseada
  isSpanishLang: boolean;
  isEnglishLang: boolean;
  isDeutchLang: boolean;
  isItLang: boolean;
  isFrLang: boolean;

  

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  constructor(private viewportScroller: ViewportScroller, private route: ActivatedRoute, private router: Router,private translate: TranslateService) {
    this.isSpanishLang = this.translate.currentLang === 'es';
    this.isEnglishLang = this.translate.currentLang === 'en';
    this.isDeutchLang = this.translate.currentLang === 'de';
    this.isItLang = this.translate.currentLang === 'it';
    this.isFrLang = this.translate.currentLang === 'fr';
  }

  public onClick(elementId: string): void { 
    const yOffset = this.isNavFixed ? -90 : -80; // Ajusta el valor según la altura de tu barra de navegación
    const element = document.getElementById(elementId);
    
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      this.viewportScroller.scrollToPosition([0, elementPosition + yOffset]);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const targetPosition = 500; // Cambia esto a la posición en la que quieres que la barra se vuelva fija
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    
    if (scrollPosition >= targetPosition) {
      this.passedPosition = true;
    } else {
      this.passedPosition = false;
    }

    if (this.passedPosition) {
      this.isNavFixed = true;
    } else {
      this.isNavFixed = false;
    }

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment === 'eft2') {
        this.onClick('eft2');
      }
      if (fragment === 'battery') {
        this.onClick('efbatteryt2');
      }
      if (fragment === 'byd') {
        this.onClick('byd');
      }
    });
    this.translate.onLangChange.subscribe((event) => {
      this.isSpanishLang = event.lang === 'es';
      this.isEnglishLang = event.lang === 'en';
    });

    this.translate.onLangChange.subscribe((event) => {
      this.isSpanishLang = event.lang === 'es';
      this.isEnglishLang = event.lang === 'en';
      this.isDeutchLang = event.lang === 'de';
      this.isItLang = event.lang === 'it';
      this.isFrLang = event.lang === 'fr';
    });
  }
}
