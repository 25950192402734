<a class="scroll-to-top hidden" (click)="scrollToTop()">
    <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
  </a>
  <div class="main-banner-white-service" style="height:350px; background-color:#F8F8F8;">
    <div class=" pt-70 container" >
        <h1 class="text-start texto-negro titulo-chico" style="margin-top:5em;"[innerHTML]="'Events'|translate"></h1>
        <p class="text-start texto-negro mt-4 texto-chico" [innerHTML]="''|translate"></p>
  
    </div>
  </div>
  