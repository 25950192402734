<a class="scroll-to-top hidden" (click)="scrollToTop()">
  <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-white-service" style="height:500px; background-color:#FFFFFF;">
  <div class="container pt-70" >
      <h1 class="text-start texto-negro titulo-chico" style="margin-top:3em;"[innerHTML]="'texto69'|translate"></h1>
      <p class="text-start texto-negro mt-4 texto-chico"[innerHTML]="'At BYD BatteryBox, we like rewarding installers for their hard work!'|translate" ></p>
      <div class="d-flex mt-4" >
        <a class="btn btn-no btn-byd5 texto-blanco texto-chico" style="padding-left:40px;padding-right:40px;border-radius:2px" href="https://support.eft-systems.de/" target="_blank" rel="noopener noreferrer"
        [innerHTML]="'Register as BePartner'|translate">
          
        </a>
    </div>
  </div>
</div>

<div class="main-banner-service-img" style="background-color:#F0F0F0" >
  <div class="row no-gutter" >
      <div class="col-lg-6 col-md-12 d-flex flex-column algin-items-center justify-content-center" >
        <div class="container ms-5 cont-resp">              <h1 class="text-start texto-negro mb-3  titulo-chcio"[innerHTML]="'HOW DOES BEPARTNER WORK?'|translate"></h1>
              <small class="texto-chico"[innerHTML]="'texto70'|translate"></small>

              <h6 class="letra-bold mt-5 mb-3 texto-chico"[innerHTML]="'HOW TO SIGN UP?'|translate"></h6>
              <small class="mb-3 texto-chico" [innerHTML]="'texto71'|translate"></small>
              <h6 class="letra-bold mb-3 mt-3" [innerHTML]="'HOW TO EARN POINTS?'|translate"></h6>
              <small class="mb-3 texto-chico" [innerHTML]="'texto72'|translate"> </small>.

          </div>
      </div>
      <div class="col-lg-6 col-md-12 " style="background-color: #F8F8F8; height:600px">
          <img src="/assets/eft/fotos/BEPARTNER/bepartner1.jpg"  >
      </div>
  </div>
</div>
<div class="main-banner-products-img" style="background-color:#F8F8F8; height:auto;" >
  <div class="row no-gutter"style="margin-top:1em;" >
      <div class="col-lg-6 col-md-12 text-end orden2">
          <img src="/assets/eft/fotos/BEPARTNER/Tamaño real.png" alt="" height="auto" width="90%">
      </div>
      <div class="col-lg-6 col-md-12 d-flex flex-column algin-items-center justify-content-center orden1" >
        <div class="container ms-5 cont-resp">
              <h1 class="text-start texto-negro mb-3 titulo-chico" [innerHTML]="'WIN POINTS TO UNLOCK BENEFITS'|translate"></h1>
              <small class="texto-chico"[innerHTML]="'texto73'|translate"></small>
              <h6 class="letra-bold mb-3 mt-5 texto-chico"[innerHTML]="'REWARD POINTS'|translate"></h6>
              <small class="mb-3 texto-chico"[innerHTML]="'texto74'|translate"></small>
              <h6 class="letra-bold mb-3 mt-3 texto-chico"[innerHTML]="'STATUS POINTS'|translate"></h6>
              <small class="mb-3 texto-chico"[innerHTML]="'texto75'|translate"></small>
          </div>
      </div>
  </div>
</div>