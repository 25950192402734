import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {


  collapsed: {[key: string]: boolean} = {
    collapseDatasheet: true,
    collapseDatasheet2: true,
    collapseDatasheet3: true,
    collapseCompatibility: true,
    collapseCompatibility2: true,
    collapseCompatibility3: true,
    collapseservice: true,
    collapseservice2: true,
    collapseservice3: true,
    collapsemanuals: true,
    collapsemanuals2: true,
    collapsemanuals3: true,
    collapsewarranty: true,
    collapsewarranty2: true,
    collapsewarranty3: true,
    collapsecertificates: true,
    collapsecertificates2: true,
    collapsecertificates3: true,
  };
  constructor(private viewportScroller: ViewportScroller) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }


  toggleCollapse(target: string): void {

    this.collapsed[target] = !this.collapsed[target];
  }
  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }
}
