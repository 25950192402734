<div class="main-banner-white-service" style="height:500px" id="servicio">
    <div class=" pt-70 texto-centrado-h1" style="padding-left: 23%;padding-right: 23%;" id="servicio1">
        <h1 class="titulo-chico texto-negro" style="margin-top:5em"[innerHTML]="'texto51'|translate"></h1>
        <p class="text-center texto-negro mt-3 texto-chico" [innerHTML]="'texto52'|translate"></p>

    </div>
  </div>
  <div [ngClass]="{'nav-about': true, 'nav-fixed': isNavFixed}" class="nav-about" style="background-color: #FFFFFF; height:40px;" >
    <p class="hover nav-letra"> <a class="texto-negro letra-bold texto-chico"  (click)="onClick('TECHNICAL')"[innerHTML]="'TECHNICAL SUPPORT'|translate" >  </a></p>
    <p class="hover nav-letra"> <a class="texto-negro letra-bold texto-chico" (click)="onClick('TRAININGS')" style=" padding-left:1em"[innerHTML]="'TRAININGS'|translate">  </a></p>
    <p class="hover nav-letra"> <a class="texto-negro letra-bold texto-chico" (click)="onClick('END')" style="padding-left:1em"[innerHTML]="'END OF LIFE'|translate">  </a></p>
    
</div>

  
  
<a class="scroll-to-top hidden" (click)="scrollToTop()">
    <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-products-img" style=" height:650px" >
    <img src="/assets/eft/fotos/SERVICE/Service1.jpg" alt="" >
  </div>
  <div class="panel-rojo d-flex flex-column align-items-center justify-content-center" style="background-color: #DF280D; height: 500px">
    <p class="texto-blanco text-center d-flex flex-column align-items-center justify-content-center text-right texto-grande" style="  color:#F0F0F0"[innerHTML]="'texto53'|translate">
    </p>
</div>

  <div class="main-banner-service-img" style="background-color:#F8F8F8" id="TECHNICAL">
    <div class="row no-gutter" >
        <div class="col-lg-6 col-md-12 d-flex flex-column algin-items-center justify-content-center" >
            <div class="container ms-5 cont-resp">
                <h1 class="text-start texto-negro texto-grande" [innerHTML]="'NEED TECHNICAL SUPPORT?'|translate"></h1>
                <small class="texto-chico"[innerHTML]="'This is how you can contact us'|translate"></small><br><br><br>

                <!-- <h6 class="letra-bold mb-3 texto-chico"[innerHTML]="'IF YOU ALREADY ARE A MEMBER OF BEPARTNER'|translate"></h6> -->
                <small class="mb-3 texto-chico"[innerHTML]="'Log in to your account through the Installer'|translate"> </small>
                <!-- <h6 class="letra-bold mb-3 mt-3 texto-chico" [innerHTML]="'IF YOU ARE NOT A BEPARTNER MEMBER'|translate"></h6> -->
                <!-- <small class="mb-3 texto-chico"[innerHTML]="'texto54'|translate"></small> -->

            </div>
        </div>
        <div class="col-lg-6 col-md-12 " style="background-color: #F8F8F8; height:600px">
            <img src="/assets/eft/fotos/SERVICE/Service2.jpg"  >
        </div>
    </div>
</div>
<div class="main-banner-products-img" style="background-color:#F8F8F8; height:auto;" id="TRAININGS">
    <div class="row no-gutter" >
        <div class="col-lg-6 col-md-12 text-end orden2">
            <img src="/assets/eft/fotos/SERVICE/Service3.jpg" alt="">
        </div>
        <div class="col-lg-6 col-md-12 d-flex flex-column algin-items-center justify-content-center orden1">
            <div class="container ms-5 cont-resp">
                <h1 class="text-start texto-negro texto-grande"[innerHTML]="'texto55'|translate">
                </h1>
                <small [innerHTML]="'This is how you can contact us'|translate"></small><br><br><br>

                <h6 class="letra-bold mb-3 texto-chico"[innerHTML]="'FOR ONLINE TRAINING THAT COVERS ALL THE BASICS'|translate"></h6>
                <small class="mb-3 texto-chico" [innerHTML]="'texto56'|translate"></small>
                <h6 class="letra-bold mb-3 mt-3 texto-chico"[innerHTML]="'FOR A MORE ADVANCED LIVE TRAINING'|translate"></h6>
                <small class="mb-3 texto-chico"[innerHTML]="'Sign up for any of the following webinars or trainings in your area!'|translate"></small>
                <div class="container">
                  <div class="row mt-3">
                    <div class="d-flex col-6" style="padding-left: 0;">
                        <select [(ngModel)]="cambioIdioma" (ngModelChange)="cambiarIdioma()" class="btn btn-no btn-byd4 texto-chico letra-bold text-left" style="background-color: rgba(221, 221, 221, 0.49);">
                            <option class="margen" value="" style="text-align: left;" [innerHTML]="'Select Language'|translate"></option>
                            <option class="margen" value="English" style="text-align: left;">English</option>
                            <option class="margen" value="Deutsch" style="text-align: left;">Deutsch</option>
                            <option class="margen" value="Español" style="text-align: left;">Español</option>
                            <option class="margen" value="Français" style="text-align: left;">Français</option>
                            <option class="margen" value="Italiano" style="text-align: left;">Italiano</option>
                            <option class="margen" value="Polish" style="text-align: left;">Poland</option>
                        </select>
                    </div>
                </div>
                
                
            <div *ngIf="datosFiltrados">
                  <div class="row">
                    <div *ngFor="let dato of datosFiltrados; let i = index" style="padding-left:0" class="col-md-6">
                      <div class="card texto-chico letra-bold mt-3" style="width: 35vh;" rel="noopener noreferrer">
                        <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                          <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 35vh">{{ dato.nombre + ',' }}</b>
                          <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 35vh">{{ ' ' + dato.pais + ',' }}</b>
                          <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 35vh">{{ ' ' + dato.ciudad }}</b>

                        <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 35vh; font-weight: 1;">{{ dato.fecha }}</p><br>
                      </ng-container>
                        <div *ngIf="dato.url_evento" class="text-center" style="background-color: red; padding: 10px; width: 35vh">
                          <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER' | translate" style="color: white"></a>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div *ngIf="datosFiltrados.length === 0">
                    <div class="row">
                      <div class="card texto-chico letra-bold mt-3" style="width: 50%; height:auto" rel="noopener noreferrer">
                        <p class="mt-3" [innerHTML]="'En este momento no hay formaciones disponibles. Consulte nuestra página con regularidad para estar al día'|translate" style="color:red; font-weight:100"></p><br>
                    </div>
                  </div>
                </div>

                  <div class="row" >
                    <div *ngFor="let dato of datos; let i = index" style="padding-left:0" class="col-md-6">
                      <div class="card texto-chico letra-bold mt-3" style="width: 35vh;" rel="noopener noreferrer">
                        <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                          <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 35vh">{{ dato.nombre + ',' }}</b>
                          <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 35vh">{{ ' ' + dato.pais + ',' }}</b>
                          <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 35vh">{{ ' ' + dato.ciudad }}</b>
                      
                      
                        <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 35vh; font-weight: 1;">{{ dato.fecha }}</p><br>
                      </ng-container>
                        <div *ngIf="dato.url_evento" class="text-center" style="background-color: red; padding: 10px; width: 35vh">
                          <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER' | translate" style="color: white"></a>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                </div>
                </div>
            </div>
        </div>
<div class="main-banner-white-service " style="height: auto; background-color:#F0F0F0" id="END">
    <div class=" pt-70 texto-centrado-h1">
        <h1 class=" texto-negro mb-5 titulo-chico" style="padding-left: 15%;padding-right: 15%;"[innerHTML]="'texto57'|translate"></h1>
        <p class="text-center texto-negro texto-chico" style="font-size:20px"[innerHTML]="'texto58'|translate"></p>

    </div>
  </div>
<div class="main-banner-products-img padding-top-resp" style="background-color:#F0F0F0; height:auto" >
    <div class="row no-gutter" >
        <div class="col-lg-6 col-md-12 d-flex flex-column algin-items-center justify-content-center" >
            <div class="container ms-5 cont-resp">
                <h1 class="text-start texto-negro mb-3 texto-grande" [innerHTML]="'THIS IS HOW IT WORKS'|translate"></h1>
                <small class="texto-chico"[innerHTML]="'texto59'|translate"></small><br><br><br>

                <h6 class="letra-bold mb-3 texto-chico"[innerHTML]="'texto60'|translate"></h6>
                <small [innerHTML]="'texto61'|translate"></small>

                <h6 class="letra-bold mb-3 mt-4 texto-chico" [innerHTML]="'All serial numbers of your batteries'|translate"></h6>
                <hr class="custom-hr">
                <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'Tell us if you need packaging'|translate"></h6>
                <hr class="custom-hr">
                <h6 class="letra-bold mb-3 texto-chico" [innerHTML]="'texto62'|translate"></h6>
                <h6 class="letra-bold mb-3 mt-5 texto-chico"[innerHTML]="'texto63'|translate"></h6>
                <small [innerHTML]="'texto64'|translate"></small>


            </div>
        </div>
        <div class="col-lg-6 col-md-12 no-padding" style="background-color: #F0F0F0;">
            <img src="/assets/eft/fotos/SERVICE/HVS Detalle.png" alt="" height="auto" width="80%">
        </div>
    </div>
</div>
<div class="container-service" style="height: auto; background-color:#F8F8F8; ">
    <div style="padding: 2.5%; background-color:#F8F8F8" >
        <div class="row">
                    <div class="col-6 mt-5  ">
            <p class="texto-chico"[innerHTML]="'texto66'|translate"></p>
            <h6 class="letra-bold mb-3 mt-3 texto-chico"[innerHTML]="'REGISTRATION NUMBERS PER COUNTRY'|translate"></h6>
            <div class="row">
                <div class="col-3 ">
                    <small>Germany</small><br>
                    <small>UK</small><br>
                    <small>Italy Batteries</small><br>
                    <!-- <small>Switzerland</small><br> -->
                    <small>Netherlands</small><br>
                    <small>Poland</small><br>
                    <small>France</small><br>
                    <!-- <small>Sweden</small><br> -->
                    <small>Spain</small><br>
                    <small>Austria</small><br>
                    <small>Lithuania</small><br>
                    <small>Sweden</small><br>
                    <!-- <small>Czech Republic</small><br> -->
                </div>
                <div class="col-3">
                    <small>stiftung-ear</small><br>
                    <small>NPWD</small><br>
                    <small>ERION ENERGY</small><br>
                    <!-- <small>INOBAT</small><br> -->
                    <small>Stibat</small><br>
                    <small>ASEKOL</small><br>
                    <small>ADEME</small><br>
                    <!-- <small>El-Kretsen</small><br> -->
                    <small>RII_PYA</small><br>
                    <small>UFH</small><br>
                    <small>GIA</small><br>
                    <small>EPA</small><br>
                    <!-- <small>ECOBAT</small><br> -->
                </div>
                <div class="col-3">
                    <small>DE 88485017</small><br>
                    <small>BPRN09074</small><br>
                    <small>IT21060P00007095</small><br>
                    <!-- <small>20001065</small><br> -->
                    <small>41350</small><br>
                    <small>BDO 000547938</small><br>
                    <small>HRB 15183</small><br>
                    <!-- <small>298989191</small><br> -->
                    <small>3074</small><br>
                    <small>One per wholesaler</small><br>
                    <small>NA</small><br>
                    <small>DE298989191</small><br>
                    <!-- <small>ECOBAT EC/23/P/472</small><br> -->
                </div>
            </div>

        </div>
            <div class="col-12 col-md-6 mt-3">
                <h6 class="letra-bold mb-3 mt-3 texto-chico"[innerHTML]="'PRODUCER EXTENDED RESPONSIBILITY'|translate"></h6>
                <p class="texto-chico"[innerHTML]="'texto67'|translate"></p>
                <h6 class="letra-bold mb-3 mt-3 texto-chico"[innerHTML]="'FURTHER LEGAL INFORMATION'|translate"></h6>
                <p style="font-size: smaller;"[innerHTML]="'texto68'|translate"></p>
            </div>
        </div>
    </div>
</div>



