import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TrainningsService } from 'src/app/trainnings.service';


interface Evento {
  fecha: string;
  pais: string;
  ciudad: string;
  url_evento: string;
  nombre: string;
  idioma: string;
}
@Component({
  selector: 'app-home-twelve',
  templateUrl: './home-twelve.component.html',
  styleUrls: ['./home-twelve.component.scss']
})
export class HomeTwelveComponent implements OnInit {
  isNavFixed: boolean = false;
  passedPosition: boolean = false;
  isSpanishLang: boolean;
  mostrar: boolean;
  isEnglishLang: boolean;
  public datos: Evento[] = [];
  cambioIdioma: string = '';
  datosFiltrados: any[] = [];



  constructor(
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private trainings: TrainningsService
  ) {
    this.isSpanishLang = this.translate.currentLang === 'es';
    this.isEnglishLang = this.translate.currentLang === 'en';

    this.datosFiltrados = [];

  }

  public onClick(elementId: string): void {
    const yOffset = this.isNavFixed ? -90 : -80;
    const element = document.getElementById(elementId);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      this.viewportScroller.scrollToPosition([0, elementPosition + yOffset]);
    }
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const targetPosition = 500; // Cambia esto a la posición en la que quieres que la barra se vuelva fija
    const scrollThreshold = 200; // Ajusta este valor según el punto en el que deseas que aparezca el botón

    
    if (scrollPosition >= targetPosition) {
      this.passedPosition = true;
    } else {
      this.passedPosition = false;
    }

    if (this.passedPosition) {
      this.isNavFixed = true;
    } else {
      this.isNavFixed = false;
    }

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.isSpanishLang = event.lang === 'es';
      this.isEnglishLang = event.lang === 'en';
      this.cambiarIdioma();
    });
  
    this.getEventosWeb(); 
  }

  getEventosWeb() {
    this.trainings.getEventosWeb(this.datos).subscribe({
      next: (r: any) => {
        const nuevosDatos = r.message || [];
        console.log(nuevosDatos);
  
        if (this.mostrar && this.cambioIdioma) {
          this.datosFiltrados = nuevosDatos.filter(d => d.idioma === this.cambioIdioma);
        } else {
          this.datosFiltrados = nuevosDatos;
        }
      },
      error: (error: any) => {
        console.error('An error occurred while fetching events:', error);
      }
    });
  }
  
  
  
cambiarIdioma() {
  this.mostrar = false;
  console.log('Nuevo valor:', this.cambioIdioma);
  this.getEventosWeb(); 
  this.mostrar = true;
}
  
  
  
}
