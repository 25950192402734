<div></div>
<a class="scroll-to-top hidden" (click)="scrollToTop()">
    <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>

<div class="main-banner-home "> 
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-texts">
                    <h1 class="mobile-font-size-sm letra-bold titulo-grande" [innerHTML]="'WE ARE THE ENERGY FOR TOMORROW'|translate"></h1>
                    <a href="#seccion-destino"><img src="/assets/eft/iconografia/menu/SCROLL.svg" alt=""></a><br><br><br>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="banner-home-text d-flex align-items-center justify-content-center" id="seccion-destino" style="height:800px; background-color:00000B;">
          <div class=" ">
            <p class=" text-center texto-blanco texto-grande" style="line-height: 1.2em;" [innerHTML]="'texto1'|translate">
              <br>
              
              <br><br>
              <a class="text-center texto-blanco texto-centrado-a texto-chico letra-bold"style="line-height: 1.2em;" routerLink="/about">{{"Learn more about us"|translate}} <span style="color:#DF280D">➔</span> </a>
            </p>
          </div>
        </div>
        <div class="main-banner-white-home-1" style="padding-top:10em; background-color:#F8F8F8">
                  <div class=" texto-centrado-h1">
                      <h1 class="mt-5 texto-negro titulo-chico" style="line-height: 1.2em;"[innerHTML]="'UNIVERSAL SYSTEMS PERFECTLY TAILORED TO YOUR INDIVIDUAL NEEDS'|translate"></h1>
                      <p class="text-center texto-negro mt-5 texto-chico" [innerHTML]="'texto2'|translate"></p>
                      <br>
                      <img src="/assets/eft/fotos/BEPARTNER/Tamaño real.png" alt="" style="height:100%; width:auto; padding-top: 6em">

                  </div>
                  <div >
                  </div>
              </div>
              <div class="bg-fffdfd pt-70 pb-70 banner-home-different" id="welcome-raion" style="background-color: #F0F0F0;">
                <div class="container">
                    <div class="section">
                        <div class="parrafos">
                            <div class="col-lg-6 welcome-title1"   >
                                <h1 class="text-start titulo-chico" [innerHTML]="'WHAT MAKES BATTERYBOX DIFFERENT?'|translate"></h1>
                            </div>
                            <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2" style="font-size:14px;">
                                <p class="texto-chico "[innerHTML]="'Suitable for any application: Off-Grid, Self consuption, Optimization'|translate"> </p>
                                <hr style="color:black">
                                <p class=" texto-chico"[innerHTML]="'Reliable in any application, from your basement to the arctic'|translate"></p>
                                <hr style="color:black">
                                <p class="texto-chico "[innerHTML]="'Compatible with the best inverter brands'|translate"></p>
                                <hr style="color:black">
                                <p class="texto-chico "[innerHTML]="'Extendable anytime to increase the battery capacity'|translate"></p>
                                <hr style="color:black">

                            </div>
                        </div>

                    </div>
                </div>
              </div>

              <div class="main-banner-red d-flex align-items-center justify-content-center" style="background-color: black;" id="welcome-raion">
                <div class="container">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="col-lg-6">
                        <p class="text-start texto-blanco mt-2 titulo-chico" [innerHTML]="'Explore all products'|translate"></p>
                    </div>
                    <div class="col-lg-6">
                        <a routerLink="/products"><h1 class="text-end texto-blanco mt-3 titulo-chico" style="font-size: 25px; padding-right: 3em;">➔</h1></a>
                    </div>
                </div>
            </div>
            </div>
              <div class="main-banner-white-home   pt-100 " style="background-color: #F8F8F8;">
                <div class="container ">
                  <div style="margin-left: 10px;" class="banner-if mt-resp">
                    <h5 class="mb-3 texto-negro titulo-chico"[innerHTML]="'IF IT S INSTALLERS  FAVORITE, IT IS FOR A REASON'|translate"></h5>
                    <p class=" texto-negro texto-chico" [innerHTML]="'texto3'|translate"></p>
                  </div>
                    <br>
                    <owl-carousel-o [options]="carouselOptions">
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-two" style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                          <div *ngIf="!isDeutchLang" class="single-services">
                                            <div  class="container-img text-center">
                                              <img src="/assets/eft/fotos/HOME/Dominic Weber.png" height="200px" width="100%"  alt="first slide"> 
                                          </div>
                                            <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                                <div >
                                                  <small class="texto-negro texto-chico" [innerHTML]="'texto4'|translate">
                                                    
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="services-content-hom"style="background-color: black;">
                                                <div >
                                                  <h6 class="texto-blanco texto-chico"[innerHTML]="'Dominic Weber, Energie Freiamt AG'|translate"></h6>
                                                </div>
                                            </div>
                                          </div>                       
                                          <div *ngIf="isDeutchLang" class="single-services" style="background-color: #F0F0F0;">
                                            <div  class="container-img text-center">
                                              <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/ZEhE_nfoj5M" allowfullscreen height="400px" width="100%" ></iframe>
                                              </div>
                                          </div>
                                            <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                                <div >
                                                  <small class="texto-negro texto-chico" [innerHTML]="'texto4'|translate">
                                                    
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="services-content-hom"style="background-color: black;">
                                                <div >
                                                  <h6 class="texto-blanco texto-chico"[innerHTML]="'Dominic Weber, Energie Freiamt AG'|translate"></h6>
                                                </div>
                                            </div>
                                          </div>                       
                                         </div>
                                      </div>
                                  </div>
                      </ng-template>
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div *ngIf="!isDeutchLang" class="single-services">
                                      <div class="container-img text-center">
                                            <img src="/assets/eft/fotos/HOME/Installer1.jpg"  alt="Second slide"> 
                                        </div>
                                          <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto5'|translate"></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Daniel Lochmaier, Installer'|translate"></h6>
                                              </div>
                                          </div>
                                        </div>
                                        <div *ngIf="isDeutchLang" class="single-services" style="background-color: #F0F0F0;">
                                          <div class="container-img text-center">
                                            <div class="embed-responsive embed-responsive-16by9">
                                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/SeTI0iU0uV0" allowfullscreen height="400px" width="100%" ></iframe>
                                            </div>
                                          </div>
                                          <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto5'|translate"></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Daniel Lochmaier, Installer'|translate"></h6>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                      </ng-template>
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div *ngIf="!isDeutchLang" class="single-services">
                                      <div class="container-img text-center">
                                            <img src="/assets/eft/fotos/HOME/Installer2.jpg" height="380px"  alt="services-img"> 
                                        </div>
                                          <div class="services-content"style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto6'|translate" ></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                
                                              <div >
                                                <h6 class="texto-blanco texto-chico" [innerHTML]="'Josef Hainzinger, Stumbaum GMBH'|translate"></h6>
                                              </div>
                                              
                                          </div>
                
                                        </div>
                                        <div *ngIf="isDeutchLang" class="single-services"  style="background-color: #F0F0F0;">
                                          <div class="container-img text-center">
                                            <div class="embed-responsive embed-responsive-16by9">
                                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/U6GtTJ7fahQ" allowfullscreen height="400px" width="100%" ></iframe>
                                            </div>
                                          </div>
                                          <div class="services-content"style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto6'|translate" ></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                
                                              <div >
                                                <h6 class="texto-blanco texto-chico" [innerHTML]="'Josef Hainzinger, Stumbaum GMBH'|translate"></h6>
                                              </div>
                                              
                                          </div>
                
                                        </div>
                                      </div>
                                      </div>
                                  </div>
                        </ng-template>
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div *ngIf="!isDeutchLang" class="single-services">
                                      <div class="container-img text-center">
                                            <img src="/assets/eft/fotos/HOME/Installer3.jpg" height="380px"  alt="services-img"> 
                                        </div>
                                          <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto7'|translate"></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Karsten Schulz, Sailer Energy'|translate">
                                                </h6>
                                              </div>
                                          </div>
                                        </div>
                                        <div *ngIf="isDeutchLang" class="single-services"  style="background-color: #F0F0F0;">
                                          <div class="container-img text-center">
                                            <div class="embed-responsive embed-responsive-16by9">
                                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/Vpo6VqoSRf0" allowfullscreen height="400px" width="100%" ></iframe>
                                            </div>
                                          </div>
                                          <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico"[innerHTML]="'texto7'|translate"></small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Karsten Schulz, Sailer Energy'|translate">
                                                </h6>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                      </div>
                                  </div>
                      </ng-template>
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div *ngIf="!isDeutchLang" class="single-services">
                                      <div class="container-img text-center">
                                            <img src="/assets/eft/fotos/HOME/Franco Bonutto.png" height="380px"  alt="services-img"> 
                                        </div>
                                          <div class="services-content " style="background-color: #F0F0F0;height: 230px">
                                            <small class="texto-negro texto-chico" [innerHTML]="'texto8'|translate">
                                             
                                              </small>
                                              <div >
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                  
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Franco Bonutto, Soltris GmbH'|translate"></h6>
                                              </div>
                                              
                                          </div>
                  
                                        </div>
                                        <div *ngIf="isDeutchLang" class="single-services">
                                          <div class="container-img text-center">
                                            <div class="embed-responsive embed-responsive-16by9">
                                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/ORodZ4hZ85k" allowfullscreen height="400px" width="100%" ></iframe>
                                            </div>
                                          </div>
                                          <div class="services-content " style="background-color: #F0F0F0;height: 230px">
                                            <small class="texto-negro texto-chico" [innerHTML]="'texto8'|translate">
                                             
                                              </small>
                                              <div >
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                  
                                              <div >
                                                <h6 class="texto-blanco texto-chico"[innerHTML]="'Franco Bonutto, Soltris GmbH'|translate"></h6>
                                              </div>
                                              
                                          </div>
                  
                                        </div>
                                      </div>
                                      </div>
                                  </div>
                      </ng-template>
                      <ng-template carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div *ngIf="!isDeutchLang" class="single-services">
                                      <div class="container-img text-center">
                                            <img src="/assets/eft/fotos/HOME/Michel Baumgartner.png" height="380px" width="420px"  alt="services-img"> 
                                        </div>
                                          <div class="services-content"style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico" [innerHTML]="'texto9'|translate">
                                                  
                                                  </small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                  
                                              <div >
                                                <h6 class="texto-blanco texto-chico" [innerHTML]="'Michel Baumgartner, Posch & Partner AG'|translate"></h6>
                                              </div>
                                              
                                          </div>
                  
                                        </div>
                                        <div *ngIf="isDeutchLang" class="single-services">
                                          <div class="embed-responsive embed-responsive-16by9">
                                            <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/4SflTg3cYAk" allowfullscreen height="400px" width="100%" ></iframe>
                                          </div>
                                          <div class="services-content"style="background-color: #F0F0F0;height: 230px">
                                              <div >
                                                <small class="texto-negro texto-chico" [innerHTML]="'texto9'|translate">
                                                  
                                                  </small>
                                              </div>
                                          </div>
                                          <div class="services-content-hom"style="background-color: black;">
                  
                                              <div >
                                                <h6 class="texto-blanco texto-chico" [innerHTML]="'Michel Baumgartner, Posch & Partner AG'|translate"></h6>
                                              </div>
                                              
                                          </div>
                  
                                        </div>
                                      </div>
                                      </div>
                                  </div>
                      </ng-template>
                      <ng-template  *ngIf="!isDeutchLang" carouselSlide>
                          <div class="main-banners item-bg-three"style="height: auto;">
                              <div class="d-table">
                                  <div class="d-table-cell">
                                    <div class="single-services">
                                      <div class="container-img text-center">
                                              <img src="/assets/eft/fotos/HOME/Can Davu.png" height="260px"  alt="services-img"> 
                                          </div>
                                            <div class="services-content "style="background-color: #F0F0F0;height: 230px">
                                                <div >
                                                  <small class="texto-negro texto-chico" [innerHTML]="'texto10'|translate"></small>
                                                </div>
                                            </div>
                                            <div class="services-content-hom"style="background-color: black;">
                                                <div >
                                                  <h6 class="texto-blanco texto-chico"[innerHTML]="'Can Davu, Schön Solar GmbH'|translate"></h6>
                                                </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      </div>
                      </ng-template>
                  </owl-carousel-o>
                </div>
                <div >
                </div>
            </div>

