<a class="scroll-to-top hidden" (click)="scrollToTop()">
  <img src="/assets/eft/iconografia/menu/SCROLL rotated.svg" alt="">
</a>
<div class="main-banner-white-service" style="height:450px; background-color:#F8F8F8;">
    <div class="container pt-70" style="padding-left: 10%;" >
        <h1 class="text-start texto-negro titulo-chico" style="margin-top:5em;"[innerHTML]="'texto76'|translate"></h1>
        <p class="text-start texto-negro mt-4 texto-chico"[innerHTML]="'texto77'|translate" ></p>

    </div>
  </div>
  <div class="main-banner-white-home "style="padding-left: 10%;padding-right:10%; background-color:#F8F8F8">
    <div class="container">
        <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="single-services">
              <div class="container-img text-center">
                <img src="/assets/eft/fotos/NOTICIA 1.jpg" height="280px" width="100%"  alt="services-img"> 
            </div>
              <div class="services-content"style="background-color: #F0F0F0;height: 300px">

                  <div >
                    <h6 class="texto-negro mb-3 texto-chico" [innerHTML]="'When the grid can’t cope'|translate"></h6>
                  </div>
                  <div >
                    <small class="texto-negro texto-chico" [innerHTML]="'Today there are however more and more examples, where the general power demand or the supply for demand peaks exceed the available grid power supply'|translate"></small>
                  </div>
                  
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-services"style="background-color: #F0F0F0;"  >
              <div class="container-img text-center">
                <img src="/assets/eft/fotos/NOTICIA 2_horizontal.png" height="280px"  alt="services-img" > 
            </div>
              <div class="services-content"style="background-color: #F0F0F0;height: 300px">

                  <div >
                    <h6 class="texto-negro mb-3 texto-chico" [innerHTML]="'Holiday resort in England self-supplies using solar power and storage'|translate"></h6>
                  </div>
                  <div >
                    <small class="texto-negro texto-chico"[innerHTML]="'The Baxby Manor holiday resort north of York has switched to solar energy. The extension of the grid connection was too expensive. An integrated storage unit ensures that all the electricity from the new photovoltaic system is used'|translate"></small>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-services">
              <div class="container-img text-center">
                <img src="/assets/eft/fotos/NOTICIA 3.jpg" height="280px"  alt="services-img"> 
            </div>
              <div class="services-content"style="background-color: #F0F0F0;height: 300px">

                  <div >
                    <h6 class="texto-negro mb-3 texto-chico" [innerHTML]="'Getting to the core of battery storage'|translate"></h6>
                  </div>
                  <div >
                    <small class="texto-negro texto-chico"[innerHTML]="'Battery and electrochemistry expert'|translate"></small>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div >
    </div>
</div>
<div class="main-banner-white-home pt-5"style="padding-left: 10%;padding-right:10%; background-color:#F8F8F8">
  <div class="container">
      <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="container-img text-center">
              <img src="/assets/eft/fotos/NOTICIA 4.jpg" height="280px"  alt="services-img"> 
          </div>

            <div class="services-content"style="background-color: #F0F0F0;height: 300px">

                <div >
                  <h6 class="texto-negro mb-3 texto-chico"[innerHTML]="'BYD präsentiert Zero Emission Ecosystem auf der The Smarter E'|translate"></h6>
                </div>
                <div >
                  <small class="texto-negro texto-chico"[innerHTML]="'BYD Co. Ltd., einer der weltweit größten Hersteller vonwiederaufladbaren'|translate"></small>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services" style="background-color: #F0F0F0;">
            <div class="container-img text-center">
              <img src="/assets/eft/fotos/NOTICIA 5_horizontal.png" height="280px"  alt="services-img"> 
          </div>

            <div class="services-content"style="background-color: #F0F0F0;height: 300px">

                <div >
                  <h6 class="texto-negro mb-3 texto-chico" [innerHTML]="'BYD receives “Top Brand PV Storage Europe” industry seal'|translate"></h6>
                </div>
                <div >
                  <small class="texto-negro texto-chico" [innerHTML]="'BYD is the first energy storage solution provider to receive this seal of approval across the European region in 2023, and was also the most installed system provider by installers'|translate"></small>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" >
          <div class="single-services" style="background-color: #F0F0F0;">
            <div class="container-img text-center">
              <img src="/assets/img/0201-14.jpg" height="280px" width="70%" alt="services-img"> 
          </div>

            <div class="services-content"style="background-color: #F0F0F0;height: 310px">

                <div >
                  <h6 class="texto-negro mb-3 texto-chico" [innerHTML]="'HTW battery storage title'|translate"></h6>
                </div>
                <div >
                  <small class="texto-negro texto-chico" [innerHTML]="'HTW battery storage text'|translate"></small>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div >
  </div>
</div>